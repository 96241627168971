import {defineStore} from "pinia";
import pickupPointsService from "../services/pickupPoints.service";

export const pickupPointsStore = defineStore('cartPickupPoints', {
    state: () => (
        {
            loaded: false,
            points: [],
        }
    ),
    actions: {
        async fetchPickupPoints() {
            try {
                const pickupPoints = await pickupPointsService.fetchPickupPoints();
                this.points = pickupPoints.data;
            } catch (e) {
                console.log(e);
            }
            this.setLoaded(true);
        },
        setLoaded(value) {
            this.loaded = value;
        }
    }
})