<template>
  <div class="cart-receiving">
    <CartBlockHeading :blockClass="'cart-page__block-heading'" :index="2" :title="'Способ получения'"/>

    <div class="cart-receiving__content">
      <div class="cart-receiving__tabs tabs cart-tabs">
        <ul class="cart-tabs__list">
          <li class="cart-tabs__item"
              data-cursor-expand
              :class="{'_active': checkDelivery}"
          >
            <a href="javascript:void(0);"
               class="cart-tabs__link"
               title="Службой доставки"
               @click="setDelivery"
            >
              <span class="cart-tabs__text text__cart-point">
                Службой доставки
              </span>

              <span class="cart-tabs__icon">
                <DeliveryIcon/>
              </span>
            </a>
          </li>

          <li class="cart-tabs__item"
              data-cursor-expand
              :class="{'_active': checkPickup}"
          >
            <a href="javascript:void(0);"
               class="cart-tabs__link"
               title="Самовывоз"
               @click="setPickup"
            >
              <span class="cart-tabs__text text__cart-point">
                Самовывоз
              </span>

              <span class="cart-tabs__icon">
                <PickupIcon/>
              </span>
            </a>
          </li>
        </ul>
      </div>

      <div class="cart-receiving__tab-contents">
        <div class="cart-receiving__tab-content" v-if="checkDelivery">
          <TheCartReceivingDelivery/>
        </div>
        <div class="cart-receiving__tab-content" v-if="checkPickup">
          <TheCartReceivingPickup/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheCartReceivingPickup from "./TheCartReceivingPickup.vue";
import TheCartReceivingDelivery from "./TheCartReceivingDelivery.vue";
import CartBlockHeading from "../parts/CartBlockHeading";
import DeliveryIcon from "../icons/DeliveryIcon";
import PickupIcon from "../icons/PickupIcon";
import {mapActions, mapState} from "pinia";
import {orderStore} from "../../store/order.store";

export default {
  name: "TheCartOrderReceiving",
  components: {
    TheCartReceivingDelivery,
    TheCartReceivingPickup,
    CartBlockHeading,
    DeliveryIcon,
    PickupIcon,
  },
  computed: {
    checkDelivery() {
      return this.isDelivery();
    },
    checkPickup() {
      return this.isPickup();
    },
  },
  methods: {
    ...mapActions(orderStore, [
        'isDelivery',
        'isPickup',
        'setReceiving'
    ]),
    setDelivery() {
      this.setReceiving(20);
      setTimeout(() => {
        document.dispatchEvent(new Event('cartDeliveryLabel'));
      }, 250);
    },
    setPickup() {
      this.setReceiving(10);
    },
  },
}
</script>

<style lang="scss">

</style>