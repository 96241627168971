import {onDomReady} from "../../components/dynamic/observer";

class BackgroundColorChanger {
    constructor() {
        this.offsetY = 0;
        this.className = '_grey';
        this.layout = document.querySelector('.layout');
        this.observeAreas = document.querySelectorAll('[data-background-color-area]');
        this.activeAreas = [];

        this.eventListeners();
        this.update();
    }

    eventListeners() {
        document.addEventListener('scroll', () => {
            this.offsetY = window.scrollY;
        });
    }

    update() {
        this.handleObserveAreas();
        this.setClassToLayout();

        window.requestAnimationFrame(this.update.bind(this));
    }

    handleObserveAreas() {
        this.observeAreas.forEach((area, index) => {
            const areaTop = area.offsetTop;
            const areaBottom = areaTop + area.offsetHeight;
            const activeLine = areaTop - window.innerHeight / 2;
            const inactiveLine = areaBottom - window.innerHeight / 2;

            if (this.offsetY > activeLine && this.offsetY < inactiveLine) {
                if (!this.activeAreas.includes(area)) {
                    this.activeAreas[index] = area;
                }
            } else {
                this.activeAreas.splice(index, 1);
            }
        });
    }

    setClassToLayout() {
        if (this.activeAreas.length) {
            this.layout.classList.add(this.className);
        } else {
            this.layout.classList.remove(this.className);
        }
    }
}

onDomReady(() => new BackgroundColorChanger());