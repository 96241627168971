import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Pagination, Swiper, Thumbs} from 'swiper';

onDomReady(() => {
    const sliderEl = document.querySelector('[data-product-page-main-slider]');
    const thumbSliderEl = document.querySelector('[data-product-page-thumb-slider]');
    const prevArrow = document.querySelector('[data-product-page-slider-prev]');
    const nextArrow = document.querySelector('[data-product-page-slider-next]');
    const paginationEl = document.querySelector('[data-product-page-slider-pagination]');

    if (!thumbSliderEl || !sliderEl) {
        return;
    }
    const thumbsSlider = new Swiper(thumbSliderEl, {
        spaceBetween: 10,
        slidesPerView: "auto",
        freeMode: true,
        watchSlidesProgress: true,
    });

    const slider = new Swiper(sliderEl, {
        modules: [Navigation, Pagination, Thumbs],
        loop: true,
        slidesPerView: 1,
        navigation: {
            prevEl: prevArrow,
            nextEl: nextArrow,
        },
        thumbs: {
            swiper: thumbsSlider
        },
        pagination: {
            el: paginationEl,
            type: 'bullets',
        }
    });
});