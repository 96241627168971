import {onDomReady} from "../../components/dynamic/observer";
import {FreeMode, Navigation, Swiper} from 'swiper';

onDomReady(() => {
    const sliderEl = document.querySelector('[data-product-page-collection-slider]');

    if (!sliderEl) {
        return;
    }

    const prevEl = document.querySelector('[data-product-page-collection-prev]');
    const nextEl = document.querySelector('[data-product-page-collection-next]');

    const slider = new Swiper(sliderEl, {
        modules: [Navigation],
        navigation: {
            prevEl: prevEl,
            nextEl: nextEl
        },
        breakpoints: {
            0: {
                spaceBetween: 8,
                slidesPerView: 2,
            },
            768: {
                spaceBetween: 18,
                slidesPerView: 2,
            },
            1024: {
                spaceBetween: 28,
                slidesPerView: 1,
            },
        }
    });
});