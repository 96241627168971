<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="9" fill="none" viewBox="0 0 11 9"><path stroke="#000" d="M11 4.5H1.5M5.5.5l-4 4 4 4"/></svg>
</template>

<script>
export default {
  name: "Arrow"
}
</script>

<style lang="scss">

</style>