<template>
  <div class="cart-total">
    <div class="cart-total__checkout-info-wrap">
      <div class="cart-total__total">
        <div class="cart-total__total-title text__cart-point">
          Итого
        </div>

        <div class="cart-total__amount h4">
          {{ price(amount) }} ₽
        </div>
      </div>

      <div class="cart-total__info-wrap">
        <div class="cart-total__info">
          <div class="cart-total__info-name text__small-cart">
            {{ items.length }} {{ plural }} на сумму
          </div>

          <div class="cart-total__info-value text__small-cart">
             {{ price(baseAmount) }} ₽
          </div>
        </div>

        <div class="cart-total__info" v-show="promoApplied">
          <div class="cart-total__info-name text__small-cart">
            Ваша скидка
          </div>

          <div class="cart-total__info-value text__small-cart">
            - {{ price(orderDiscount) }} ₽
          </div>
        </div>
      </div>
    </div>

    <div class="cart-total__promo-wrap">
      <div class="cart-total__form line-form line-form_light">
        <div class="cart-total__promocode-title">
          Применить промокод
        </div>

        <div class="cart-total__field line-form__field _half" data-hide-custom-cursor>
          <div class="smart-label" data-smart-label>
            <input type="text" id="order_promocode" class="cart-total__input line-form__input" v-model="promocode">

            <label for="order_promocode" class="cart-total__label smart-label__primary">
              Промокод
            </label>

            <label for="order_promocode" class="cart-total__label smart-label__secondary">
              Промокод
            </label>
          </div>

          <a href="javascript:void(0);"
             title="Промокод"
             class="cart-total__apply-button"
             data-cursor-expand
             @click="applyPromo(promocode)"
          >
            <ButtonArrow/>
          </a>
        </div>
      </div>
    </div>

    <div class="cart-total__bottom-wrap">
      <button class="cart-total__button button button__big"
              data-cursor-expand
              @click="sendOrder"
              ref="submit"
      >
        <span class="button__button-big-label-wrap">
            <span class="button__button-big-label">
                Оформить заказ
            </span>
        </span>
        <span class="button__button-big-label-anim">
            Оформить заказ
        </span>
      </button>

      <div class="cart-total__policy text__small-points">
        Подтверждая заказ вы соглашаетесь с
        <a href="#" class="cart-total__policy-link" data-cursor-expand>
          политикой конфиденциальности
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "pinia";
import {cartStore} from "../../store/cart.store";
import ItemErrors from "./ItemErrors";
import ButtonArrow from "../icons/ButtonArrow";
import {orderStore} from "../../store/order.store";

export default {
  name: "CartTotal",
  props: ['promocode'],
  components: {
    ItemErrors,
    ButtonArrow
  },
  computed: {
    ...mapState(cartStore, [
      'checkout',
      'promoApplied',
    ]),
    items() {
      return this.checkout ? this.checkout.items : [];
    },
    amount() {
      return this.checkout ? this.checkout.total : 0;
    },
    baseAmount() {
      return this.checkout ? this.checkout.base_total : 0;
    },
    orderDiscount() {
      return this.checkout ? this.checkout.discount : 0;
    },
    plural() {
      let num = this.items.length;
      let forms = ['товар', 'товара', 'товаров'];
      if (num === 0) {
        return forms[2];
      }

      let value = num % 100;

      if (value === 0) {
        return forms[2];
      }

      if (value > 10 && value < 20) {
        return forms[2];
      } else {
        let value = num % 10;
        if (value === 1) {
          return forms[0];
        } else if (value > 1 && value < 5) {
          return forms[1];
        } else {
          return forms[2];
        }
      }
    },
  },
  methods: {
    ...mapActions(cartStore, [
      'applyPromocode'
    ]),
    ...mapActions(orderStore, [
        'setPromocode',
        'send'
    ]),
    price(price) {
      return new Intl.NumberFormat('ru-RU', {style: 'decimal'}).format(price);
    },
    applyPromo(promocode) {
      this.applyPromocode(promocode);
      this.setPromocode(promocode);
    },
    async sendOrder(e) {
      e.target.disabled = true;
      await this.send();
      setTimeout(() => {
        e.target.disabled = false;
      }, 500)
    }
  },
}
</script>

<style lang="scss">

</style>