import {onDomChanges, onDomReady} from "../../components/dynamic/observer";

class OrderButtonSticky {
    constructor(selector) {
        this.container = selector;
        this.button = this.container.querySelector('[data-order-button]');
        if (!this.button) {
            console.log(`OrderButtonSticky: button in ${this.container} could not found.`);
            return;
        }

        this.update();
    }

    calculate () {
        const bounds = this.container.getBoundingClientRect();
        if (bounds.top > window.innerHeight - (15 + this.button.offsetHeight)) {
            this.container.classList.add('_sticky');
        } else {
            this.container.classList.remove('_sticky');
        }
    }

    update() {
        this.calculate();
        requestAnimationFrame(this.update.bind(this));
    }
}

document.addEventListener('ProductOrderConfigInit', () => {
    const element = document.querySelector('[data-order-button-wrap]');
    if (element) {
        new OrderButtonSticky(element);
    }
});