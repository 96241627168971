import {defineStore} from "pinia";
import orderInfoService from "../services/orderInfo.service";

export const orderInfoStore = defineStore('cartInfoItems', {
    state: () => (
        {
            loaded: false,
            infoItems: [],
        }
    ),
    actions: {
        async fetchOrderInfoItems() {
            try {
                const items = await orderInfoService.fetchOrderInfoItems();
                this.infoItems = items.data;
            } catch (e) {
                console.log(e);
            }
            this.setLoaded(true);
        },
        setLoaded(value) {
            this.loaded = value;
        }
    }
})