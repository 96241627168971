import {onDomReady} from "../../components/dynamic/observer";

class accordionTextHeight {
    elements = document.querySelectorAll('[data-text-block-height]');
    elementsWrap = document.querySelectorAll('[data-text-block-wrap]')
    elementHeight = 0;

    constructor() {
        this.init();
    }

    init() {
        this.elements.forEach((element) => {
            this.elementHeight = element.offsetHeight;
        });

        this.elementsWrap.forEach((elementWrap) => {
            console.log(elementWrap);
            console.log(this.elementHeight);
            elementWrap.style.height = this.elementHeight + 'px';
            // elementWrap.style.height = `${this.elementHeight}px`;
        });
    }
}

onDomReady(() => {
    // setTimeout(()=>{
    //     new accordionTextHeight();
    // }, 2500)
});