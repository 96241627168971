import LiveEvent from "../../components/live/live";
import {scrollToTarget} from "../../components/scroll/scroll";
import {onDomReady} from "../../components/dynamic/observer";

new LiveEvent('click', '[data-scroll-link], .scroll-link', function scrollLink(e) {
    e.preventDefault();
    let target = null;
    if (this.dataset.selector) {
        target = document.querySelector(this.dataset.selector);
    } else {
        target = document.querySelector(this.getAttribute('href'));
    }
    let offset = parseInt(this.dataset.offset) || 0;
    if (target) {
        scrollToTarget(target, offset);
    }
});

new LiveEvent('click', '[data-toggle-link], .toggle-link', function toggleLink(e) {
    e.preventDefault();
    let target = document.querySelector(this.dataset.selector);
    target.classList.toggle(this.dataset.toggle);
});

new LiveEvent('click', '[data-toggle-text], .toggle-text', function toggleText(e) {
    e.preventDefault();
    let target = document.querySelector(this.dataset.selector);
    let targetSecond = document.querySelector(this.dataset.selectorSecond);
    let oldText = this.dataset.oldText;
    let newText = this.dataset.newText;
    target.classList.toggle(this.dataset.toggle);
    targetSecond.classList.toggle(this.dataset.toggle);

    if (target.classList.contains(this.dataset.toggle)) {
        target.textContent=newText;
    } else {
        target.textContent=oldText;
    }
});

// ya-share2

// onDomReady(() => {
//     let target = document.querySelector('.ya-share2__popup');
//     target.classList.add('ya-share2__popup_visible');
// })
//
// new LiveEvent('mouseenter', '.ya-share2__link_more', function toogleList(e) {
//     e.preventDefault();
//     let target = document.querySelector('.ya-share2__popup');
//     target.classList.toggle('ya-share2__popup_visible_local');
// });
//
// new LiveEvent('mouseleave', '.ya-share2__link_more', function toogleList(e) {
//     e.preventDefault();
//     let target = document.querySelector('.ya-share2__popup');
//     target.classList.toggle('ya-share2__popup_visible_local');
// });
//
// //работает не чини
//
// new LiveEvent('mouseenter', '.share', function toogleList(e) {
//     e.preventDefault();
//     let target = document.querySelector('.ya-share2__popup');
//     target.classList.toggle('ya-share2__popup_visible_local');
// });
//
// new LiveEvent('mouseleave', '.share', function toogleList(e) {
//     e.preventDefault();
//     let target = document.querySelector('.ya-share2__popup');
//     target.classList.toggle('ya-share2__popup_visible_local');
// });

// /ya-share2