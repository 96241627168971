<template>
  <div class="cart-order-map" id="map" :class="mapClass">
    <div class="cart-order-map__map" :class="mapClass" data-cart-map></div>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import {pickupPointsStore} from "../../store/pickupPoints.store";
import {orderStore} from "../../store/order.store";

export default {
  name: "TheCartOrderMap",
  data() {
    return {
      element: null
    }
  },
  props: {
    mapClass: String,
  },
  computed: {
    ...mapState(pickupPointsStore, [
      'points',
      'loaded'
    ]),
    ...mapState(orderStore, [
      'pickupPoint'
    ])
  },
  methods: {
    initMap() {
      this.element = document.querySelector('[data-cart-map]')
      document.dispatchEvent(new CustomEvent('pickupPoint:map:init', {
        detail: {
          points: this.points,
          element: this.element
        }
      }))
    },
    initMapPending() {
      if (window.ymaps && this.loaded) {
        this.initMap();
      } else {
        setTimeout(() => {
          this.initMapPending();
        }, 500)
      }
    }
  },
  mounted() {
    this.initMapPending();
  },
  watch: {
    pickupPoint: function (value) {
      if (!this.element) {
        return;
      }
      this.element.dispatchEvent(new CustomEvent('pickupPoint:point:changed', {detail: {value}}))
    }
  }
}
</script>