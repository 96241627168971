/**
 * Кастомная штука: при наведении крестик внутри поворачивается один раз
 *
 * <a href="javascript:void(0);" data-accordion-link data-cross-rotate-link>
 *     <span class="cross"></span>
 * </a>
 * */

import {onDomReady} from "../../components/dynamic/observer";

onDomReady(() => {
    document.querySelectorAll('[data-cross-rotate-link]').forEach((link) => {
        link.addEventListener('mouseover', () => {
            if (!link.parentNode.classList.contains('_opened')){
                let cross = link.querySelector(".cross");
                cross.style.transitionDuration = '.4s';
                cross.style.transform = 'rotate(90deg)';
                setTimeout(() => {
                    cross.style.transitionDuration = '0s';
                    cross.style.transform = 'rotate(0deg)';
                    cross.style.margin = '0';
                }, 400);
            }
        })
    });
});
