import {onDomReady} from "../../components/dynamic/observer";

class Preloader {
    constructor() {
        // элемент прогрессабара в курсоре
        this.cursorPreloaderBar = document.querySelector('#preloader-bar');
        // прелоадер для мобилки
        this.mobilePreloader = document.querySelector('[data-preloader-mobile]');
        if (this.mobilePreloader) {
            // элемент прогрессабара для мобилки
            this.cursorPreloaderBarMobile = this.mobilePreloader.querySelector('#preloader-bar-mobile');
        }
        // длина окружности прогрессбара
        this.cursorCircumference = 329;

        // изображения, их загрузка влияет на процент загрузки
        this.images = Array.from(document.querySelectorAll('[data-preloader-image] img'));
        // запоминает общую длинну изображений
        this.totalLength = this.images.length;

        // текущее количество загруженых изображений
        this.imagesLoaded = 0;
        // процент загрузки
        this.percent = 0;

        this.bind();
        this.update();
    }

    bind() {
        if (this.images.length) {
            document.dispatchEvent(new CustomEvent('PreloaderInit'));
        } else {
            document.dispatchEvent(new CustomEvent('PreloaderFilled'));

            if (this.mobilePreloader) {
                setTimeout(() => {
                    this.mobilePreloader.classList.add('_hidden');
                }, 300);
            }
        }

        const intervalId = setInterval(() => {
            this.images.forEach((image, index) => {
                if (image.complete) {
                    this.imagesLoaded++;
                    this.images.splice(this.images.indexOf(image), 1);
                }

                if (this.totalLength === this.imagesLoaded) {
                    clearInterval(intervalId);

                    setTimeout(() => {
                        document.dispatchEvent(new CustomEvent('PreloaderFilled'));

                        if (this.mobilePreloader) {
                            this.mobilePreloader.classList.add('_hidden');
                        }
                    }, 1000);
                }
            });
        }, 500);

        setTimeout(() => {
            this.imagesLoaded = this.totalLength;
        }, 10000);
    }

    computedPercent() {
        this.percent = (this.imagesLoaded / this.totalLength) * 100;
    }

    computedOffset() {
        const currentValue = (this.cursorCircumference / 100) * this.percent;
        const defaultValue = this.cursorCircumference - currentValue;
        this.offset = `${currentValue} ${defaultValue}`;
    }

    setStyles() {
        if (window.innerWidth > 1023) {
            this.setStylesDesktop();
        } else {
            this.setStylesMobile();
        }
    }

    setStylesDesktop() {
        this.cursorPreloaderBar.style.strokeDasharray = `${this.offset}`;
    }

    setStylesMobile() {
        if (this.cursorPreloaderBarMobile) {
            this.cursorPreloaderBarMobile.style.strokeDasharray = `${this.offset}`;
        }
    }

    update() {
        this.computedPercent();
        this.computedOffset();
        this.setStyles();

        window.requestAnimationFrame(this.update.bind(this));
    }
}

onDomReady(() => {
    new Preloader();
});