import {defineStore} from "pinia";

export const notificationStore = defineStore('notification', {
    state: () => (
        {
            notifications: {},
            id: 0,
        }
    ),
    actions: {
        showSuccessNotification(text) {
            this.nextId();
            const id = this.id;
            const type = 'success';
            this.addNotification(id, type, text);
            setTimeout(() => {
                this.removeNotification(id);
            }, 4000);
        },
        showErrorNotification(text) {
            this.nextId();
            const id = this.id;
            const type = 'error';
            this.addNotification(id, type, text);
            setTimeout(() => {
                this.removeNotification(id);
            }, 4000);
        },
        addNotification(id, type, text) {
            const key = Math.random().toString(36).substr(2, 9);
            this.notifications[id] = { type, text, key };
        },
        removeNotification(id) {
            delete this.notifications[id];
        },
        nextId() {
            this.id += 1;
        }
    }
})