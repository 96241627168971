<template>
  <div class="empty-cart">
    <div class="empty-cart__title h1__cart">
      Ваша корзина пуста
    </div>
    <div class="empty-cart__subtitle text__main-big">
      Выберите товары в нашем каталоге
    </div>

    <div class="empty-cart__button-wrap">
      <a :href="`/catalog`"
         title="В каталог"
         class="empty-cart__button button button__big"
         data-cursor-expand
      >
        <span class="button__button-big-label-wrap">
          <span class="button__button-big-label">
            В каталог
          </span>
          <span class="button__button-big-label-anim">
            В каталог
          </span>
        </span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheEmptyCart"
}
</script>

<style lang="scss">

</style>