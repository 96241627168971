import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Pagination, Swiper, Thumbs} from 'swiper';

onDomReady(() => {
    document.querySelectorAll('[data-product-card-slider]').forEach((sliderEl) => {
        const paginationEl = sliderEl.parentNode.querySelector('[data-product-card-slider-pagination]');

        const slider = new Swiper(sliderEl, {
            modules: [Pagination],
            pagination: {
                el: paginationEl,
                type: 'bullets',
            }
        });
    })
});