import {onDomReady} from "../../components/dynamic/observer";

class Cursor {
    cursor = undefined;
    current = undefined;
    move = undefined;
    ease = undefined;
    expandItems = [];
    blackItems = [];
    arrowsItems = [];
    dragItems = [];
    plusItems = [];
    hideCustomCursorItems = [];
    removeClasses = [
        '_black',
        '_arrows_prev',
        '_arrows_next',
        '_drag',
        '_plus',
        '_expand'
    ];

    constructor() {
        this.move = document.querySelector('[data-cursor]');
        this.largeMinWidth = 1024;

        if (document.body.getBoundingClientRect().width >= this.largeMinWidth) {
            this.hideBaseCursor();
            this.cursor = {
                x: 0,
                y: 0,
            };
            this.current = {
                x: 0,
                y: 0,
            };
            this.ease = 1;
            this.order = [];
            this.currentState = [];
            this.lastState = [];
            this.isPreloaderActive = false;
            this.itemCollector();

            this.eventListeners();
            this.update();
        } else {
            this.move.classList.add('_hide')
        }
    }

    update() {
        this.lerp();
        this.move.style.transform = `matrix(1, 0, 0, 1, ${this.current.x}, ${this.current.y})`;
        this.defineState();
        const instance = this;
        window.requestAnimationFrame(() => {
            instance.update();
        });
    }

    itemCollector() {
        this.expandItems = document.querySelectorAll('[data-cursor-expand]:not([data-cursor-initialized])');
        this.blackItems = document.querySelectorAll('[data-cursor-black]:not([data-cursor-initialized])');
        this.arrowsItems = document.querySelectorAll('[data-cursor-arrows]:not([data-cursor-initialized])');
        this.dragItems = document.querySelectorAll('[data-cursor-drag]:not([data-cursor-initialized])');
        this.plusItems = document.querySelectorAll('[data-cursor-plus]:not([data-cursor-initialized])');
        this.hideCustomCursorItems = document.querySelectorAll('[data-hide-custom-cursor]');
    }

    lerp() {
        this.current.x = (1 - this.ease) * this.current.x + this.ease * this.cursor.x;
        this.current.y = (1 - this.ease) * this.current.y + this.ease * this.cursor.y;
    }

    defineState() {
        const length = this.order.length;
        if (length && !this.isPreloaderActive) {
            this.lastState = Array.from(this.currentState);
            this.order.forEach((state) => {
                if (state.enter && !this.currentState.find((current) => current === state.name)) {
                    this.currentState.push(state.name);
                } else if (!state.enter) {
                    this.currentState = this.currentState.filter(current => current !== state.name)
                }
            });
            this.currentState = this.currentState.sort();
            this.order = [];
            if (this.currentState !== this.lastState) {
                this.applyClass(this.defineClass());
            }
        }
    }

    defineClass() {
        let className = ''

        const isBlack = this.currentState.find((name) => name === 'black');
        if (isBlack) {
            className = '_black';
        }

        const isPlus = this.currentState.find((name) => name === 'plus');
        if (isPlus) {
            className = '_plus';
        }

        const isDisk = this.currentState.find((name) => name === 'disk');
        if (isDisk) {
            className = '_disk';
        }

        const isExpand = this.currentState.find((name) => name === 'expand');
        if (isExpand) {
            className += ' _expand';
        }

        if (this.currentState.length) {
            // Если одна из стрелок - возращаем стрелку
            if (this.currentState.find((name) => name === 'arrows-prev')) {
                className += ` _arrows_prev`;
            }
            if (this.currentState.find((name) => name === 'arrows-next')) {
                className += ` _arrows_next`;
            }

            if (this.currentState.find((name) => name === 'drag')) {
                className += ` _drag`;
            }
        }
        return className;
    }

    applyClass(className) {
        const classArray = className.split(' ');
        const removeClasses = this.removeClasses.filter((removeClass) => !classArray.includes(removeClass));
        removeClasses.forEach((removeClass) => this.move.classList.remove(removeClass));
        classArray.forEach((addClass) => {
            if (addClass) {
                this.move.classList.add(addClass);
            }
        });
    }

    initLoadedListeners() {
        this.expandItems.forEach((item) => {
            item.setAttribute('data-cursor-initialized', '');
            item.addEventListener('mouseenter', () => {
                this.order.push({enter: true, name: 'expand'});
            });
            item.addEventListener('mouseleave', () => {
                this.order.push({enter: false, name: 'expand'});
            });
        });

        this.blackItems.forEach((item) => {
            item.setAttribute('data-cursor-initialized', '');
            item.addEventListener('mouseenter', () => {
                this.order.push({enter: true, name: 'black'});
            });
            item.addEventListener('mouseleave', () => {
                this.order.push({enter: false, name: 'black'});
            });
        });

        this.arrowsItems.forEach((item) => {
            item.setAttribute('data-cursor-initialized', '');

            if (item.hasAttribute('data-cursor-arrows-prev')) {
                item.addEventListener('mouseenter', () => {
                    //this.hideBaseCursor();
                    this.order.push({enter: true, name: 'arrows-prev'});
                });
                item.addEventListener('mouseleave', () => {
                    //this.showBaseCursor();
                    this.order.push({enter: false, name: 'arrows-prev'});
                });
            } else {
                item.addEventListener('mouseenter', () => {
                    //this.hideBaseCursor();
                    this.order.push({enter: true, name: 'arrows-next'});
                });
                item.addEventListener('mouseleave', () => {
                    //this.showBaseCursor();
                    this.order.push({enter: false, name: 'arrows-next'});
                });
            }
        });

        this.dragItems.forEach((item) => {
            item.setAttribute('data-cursor-initialized', '');
            item.addEventListener('mouseenter', () => {
                //this.hideBaseCursor();
                this.order.push({enter: true, name: 'drag'});
            });
            item.addEventListener('mouseleave', () => {
                //this.showBaseCursor();
                this.order.push({enter: false, name: 'drag'});
            });
        });

        this.plusItems.forEach((item) => {
            item.setAttribute('data-cursor-initialized', '');
            item.addEventListener('mouseenter', () => {
                //this.hideBaseCursor();
                this.order.push({enter: true, name: 'plus'});
            });
            item.addEventListener('mouseleave', () => {
                //this.showBaseCursor();
                this.order.push({enter: false, name: 'plus'});
            });
        });

        this.hideCustomCursorItems.forEach((item) => {
           item.addEventListener('mouseenter', () => {
               if (!this.move.classList.contains('_hide')) {
                   this.move.classList.add('_hide');
               }
           });
           item.addEventListener('mouseleave', () => {
               if (this.move.classList.contains('_hide')) {
                   this.move.classList.remove('_hide');
               }
           });
        });
    }

    eventListeners() {
        const instance = this;
        window.addEventListener('mousemove', (e) => {
            instance.cursor.x = e.clientX;
            instance.cursor.y = e.clientY;
        }, false);


        window.addEventListener('cursorMove', (e) => {
            instance.cursor.x = e.detail.offsetX;
            instance.cursor.y = e.detail.offsetY;
        })

        this.initLoadedListeners();

        document.addEventListener('DOMContentMutated', () => {
            this.itemCollector();
            this.initLoadedListeners();
        });

        document.addEventListener('PreloaderInit', () => {
            this.isPreloaderActive = true;
            this.move.classList.add('_preloader');
        });

        document.addEventListener('PreloaderFilled', () => {
            this.isPreloaderActive = false;
            this.move.classList.remove('_preloader');
        });
    }

    hideBaseCursor() {
        document.body.classList.add('_hide-cursor');
    }

    showBaseCursor() {
        document.body.classList.remove('_hide-cursor');
    }
}

onDomReady(() => {
    const follower = new Cursor();
});
