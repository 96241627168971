<template>
  <div class="cart-page" v-show="!loading">
    <h1 class="content-header__cart-heading h1__cart" v-show="count">
        <span class="animation-item__inner" data-text-wrap>
            Оформление заказа
        </span>
    </h1>

    <div class="cart-page__wrap grid-block" v-show="count">
      <div class="cart-page__content-wrap">
        <TheCartPositions/>
        <CartOrderInfo/>
        <TheCartContacts/>
        <TheCartReceiving/>
        <TheCartOrderPay/>
        <TheCartOrderComment/>
      </div>

      <div class="cart-page__side-block">
        <CartTotal/>
      </div>
    </div>

    <div class="cart-page__empty" v-show="!count">
      <TheEmptyCart/>
    </div>
  </div>
</template>

<script>
import {mapState} from "pinia";
import {cartStore} from "../../store/cart.store";

import TheCartPositions from "./TheCartPositions";
import CartTotal from "./CartTotal";
import TheCartContacts from "./TheCartContacts";
import TheCartReceiving from "./TheCartReceiving";
import TheCartOrderPay from "./TheCartOrderPay";
import TheCartOrderComment from "./TheCartOrderComment";
import TheEmptyCart from "./TheEmptyCart";
import CartOrderInfo from "./CartOrderInfo.vue";

export default {
  name: "TheCartPage",
  computed: {
    ...mapState(cartStore, [
      'loading',
      'count'
    ]),
  },
  components: {
    TheCartPositions,
    CartTotal,
    TheCartContacts,
    TheCartReceiving,
    TheCartOrderPay,
    TheCartOrderComment,
    TheEmptyCart,
    CartOrderInfo,
  }
}
</script>

<style lang="scss">

</style>