import {onDomReady} from "../../components/dynamic/observer";
import {fillUrlWithFormData} from "../../components/forms/form-data";
import axios from "axios";
import _ from 'lodash';

class Filter {
    constructor(element) {
        this.form = element;
        this.checkBoxes = Array.from(this.form.querySelectorAll('input[type="checkbox"]'));
        this.reset = this.form.querySelector('[data-reset]');
        this.debounce = _.debounce(() => this.request(), 1000);
        this.bind();
    }

    bind() {
        this.bindSubmit();
        this.bindCheckboxes();
        // this.bindReset();
    }

    bindSubmit() {
        const instance = this;

        this.form.addEventListener('submit', (e) => {
            e.preventDefault();
            instance.debounce();
        });
    }

    bindCheckboxes() {
        const instance = this;
        instance.checkBoxes.forEach((input) => {
            input.addEventListener('change', instance.debounce);
        });
    }

    bindReset() {
        this.reset.addEventListener('click', (e) => {
            e.preventDefault();
            this.checkBoxes.forEach((input) => {
                input.checked = '';
                input.dispatchEvent(new Event('change'));
            })
        })
    }

    async formSubmit() {
        const response = await this.request();
        this.responseHandler(response.data);
    }

    async request() {
        const instance = this;
        const formData = new FormData(this.form);
        const action = this.form.getAttribute('action') ? this.form.getAttribute('action') : window.location.href;
        const method = this.form.getAttribute('method') ? this.form.getAttribute('method').toLowerCase() : 'get';
        const options = {
            method: method
        };
        options.url = fillUrlWithFormData(action, formData);
        axios(options).then((response) => {
            instance.responseHandler(response.data);
        });
    }

    responseHandler(data) {
        this.replaceContent(data);
        document.dispatchEvent(new Event('ArticlesContentReplaced'));
        document.dispatchEvent(new Event('DOMContentMutated'));
    }

    replaceContent(data) {
        const page = document.createElement('div');
        page.innerHTML = data;

        const newData = page.querySelector('[data-articles-list]');
        const currentData = document.querySelector('[data-articles-list]');

        if (newData) {
            currentData.innerHTML = newData.innerHTML;
        } else {
            currentData.innerHTML = '';
        }
    }
}

onDomReady(() => {
    const filterElement = document.querySelector('[data-articles-filter]');
    if (filterElement) {
        new Filter(filterElement);
    }
});