// Настройки фильтров под проект
import { FilterManagerPool } from "../../vue/filter/manager";
import { debounce } from "lodash";
import {onDomChanges, onDomReady} from "../../components/dynamic/observer";
import LiveEvent from "../../components/live/live";

const initFilterSubmits = () => {
  const filterSubmits = document.querySelectorAll('[data-filter-submit]:not([data-initialized="true"])');
  filterSubmits.forEach((filterSubmit) => {
    filterSubmit.dataset.initialized = "true";
    filterSubmit.addEventListener('click', () => {
      const manager = FilterManagerPool.getManager(filterSubmit.dataset.filterKey);
      manager.submit();
    });
  });
};

onDomReady(() => {
  // Кнопка отправки
  initFilterSubmits();

// Отправлять фильтр при любых изменениях
  document.addEventListener('filter:values-changed', debounce((e) => {
    const { filterManager } = e.detail;
    filterManager.submit();
  }, 300));
});

onDomChanges(() => {
  setTimeout(() => {
    initFilterSubmits();
  }, 100);
});
