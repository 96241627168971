import {onDomChanges, onDomReady} from "../../components/dynamic/observer";
import Queue from "./queue";
// TODO чуть чуть оптимизировать
class HoverListener {
    items = undefined;
    constructor(selector) {
        if (selector) {
            selector.dataset.initialize = "true";

            this.container = selector;
            this.items = this.container.querySelectorAll('[data-hovered-item]');

            this.imagesContainer = document.querySelector('.drop-menu__menu-images');
            this.images = [];
            this.items.forEach(item => {
                this.images.push(document.querySelector(`[data-hovered-image="${item.dataset.hoveredItem}"]`));
            })
            this.handleTimeoutIds = [];
            this.counter = 0;
            this.queue = new Queue();
            this.animationTimeout = 600;
            this.outTimeout = 300;

            this.eventListeners();
            this.update();
        }
    }

    catalogItemsMouseEnterListener() {
        const instance = this;
        instance.items.forEach((item) => {
            item.addEventListener('mouseenter', () => {
                this.removeHovered();
                this.addShadow();
                item.classList.remove('_shadow');
                item.classList.add('_hovered');

                // show images container
                this.imagesContainer.classList.remove('_hide');
                // add image to queue
                this.queue.enqueue(this.images.find(image => {
                    if (image) {
                        return image.dataset.hoveredImage === item.dataset.hoveredItem;
                    }
                }));
                // queue counter
                this.counter++;
            });
        });
    }

    catalogItemsMouseLeaveListener() {
        const instance = this;
        instance.items.forEach((item) => {
            item.addEventListener('mouseleave', () => {
                this.removeHovered();
                this.removeShadow();
            });
        });
    }

    eventListeners() {
        this.catalogItemsMouseEnterListener();
        this.catalogItemsMouseLeaveListener();

        this.container.addEventListener('mouseleave', () => {
            // clear queue and counter
            this.queue.clear();
            this.counter = 0;
            // hide images container
            this.imagesContainer.classList.add('_hide');
            // clear already running setTimeout's
            this.handleTimeoutIds.map(id => clearInterval(id));
            this.handleTimeoutIds = [];
            // remove visible from images
            setTimeout(() => {
                this.images.map(image => {
                    if (image) {
                        image.style.zIndex = '';
                        image.classList.remove('_visible');
                        image.classList.remove('_active');
                    }
                });
            }, this.outTimeout);
        });
    }

    removeHovered() {
        this.items.forEach((item) => {
            if (item.classList.contains('_hovered')) {
                item.classList.remove('_hovered');
            }
        });
    }

    addShadow() {
        this.items.forEach((item) => {
            item.classList.add('_shadow');
        });
    }

    removeShadow() {
        this.items.forEach((item) => {
            if (item.classList.contains('_shadow')) {
                item.classList.remove('_shadow');
            }
        });
    }

    update() {
        // if has queue handle it
        if (!this.queue.isEmpty) {
            const el = this.queue.dequeue();

            if (el && !el.classList.contains('_active')) {
                el.classList.add('_active');
                el.style.zIndex = this.counter;

                const id = setTimeout(() => {
                    el.classList.remove('_active');
                    el.classList.add('_visible');
                }, this.animationTimeout);
                // collect ids from setTimeout's
                this.handleTimeoutIds.push(id);
            }
        }

        window.requestAnimationFrame(this.update.bind(this));
    }
}

function init() {
    if (window.innerWidth > 1023) {
        document.querySelectorAll('[data-hovered-container]:not([data-initialize="true"])').forEach((item) => {
            const hoverListener = new HoverListener(item);
        });
    }
}

onDomReady(() => init());
onDomChanges(() => init());