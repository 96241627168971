<template>
  <div class="cart-pickup">
<!--    <ItemErrors :errors="errors.pickupPointSelected"/>-->
    <ul class="cart-pickup__radio-list">
      <li class="cart-pickup__radio-item" v-for="(point) in points" data-cursor-expand>
        <div class="cart-pickup__radio">
          <input
              type="radio"
              name="points"
              v-bind:id="'point-' + point.id"
              :value="point.id"
              @click="setPoint(point.id)"
              :checked="pickupPoint === point.id"
          >
          <label v-bind:for="'point-' + point.id" class="cart-pickup__input-label">
            <span class="cart-pickup__label-title cart-pickup__label-title_city">
              {{ point.city }}
            </span>
            <span class="cart-pickup__label-title cart-pickup__label-title_address">
              {{ point.address }}
            </span>
            <span class="cart-pickup__label-title cart-pickup__label-title_phone">
              {{ point.phone }}
            </span>

            <span class="cart-pickup__schedule-list">
              <span class="cart-pickup__label-title cart-pickup__label-title_week-schedule">
                <span class="cart-pickup__schedule-day">
                  пн — пт
                </span>
                {{ point.weekSchedule }}
              </span>

              <span class="cart-pickup__label-title cart-pickup__label-title_weekends-schedule">
                <span class="cart-pickup__schedule-day">
                  сб — вс
                </span>
                {{ point.weekendsSchedule }}
              </span>
            </span>
          </label>
        </div>

        <a href="#map"
           v-bind:for="'point-' + point.id"
           class="cart-pickup__link link link__review _line salons__to-map-link"
           data-scroll-link
        >
          <span class="cart-pickup__link-text link__review-text">
              На карте
          </span>
          <span class="cart-pickup__link-icon link__review-icon">
            <MapPoint/>
          </span>
        </a>
      </li>
    </ul>

    <div class="cart-pickup__map-wrap">
      <TheCartOrderMap :mapClass="'cart-pickup__map'"/>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from "pinia";
import ItemErrors from "./ItemErrors";
import TheCartOrderMap from "./TheCartOrderMap";
import MapPoint from "../icons/MapPoint";
import {pickupPointsStore} from "../../store/pickupPoints.store";
import {orderStore} from "../../store/order.store";

export default {
  name: "TheCartReceivingPickup",
  components: {
    ItemErrors,
    TheCartOrderMap,
    MapPoint,
  },
  computed: {
    ...mapState(pickupPointsStore, [
      'points'
    ]),
    ...mapState(orderStore, [
      'pickupPoint'
    ]),
  },
  methods: {
    ...mapActions(pickupPointsStore, [
      'fetchPickupPoints'
    ]),
    ...mapActions(orderStore, [
      'setPickupPoint'
    ]),
    setPoint(point) {
      this.setPickupPoint(point)
    }
  },
  mounted() {
    this.fetchPickupPoints().then(() => {
      if (this.points.length === 1) {
        this.setPickupPoint(this.points[0].id);
      }
    });
  }
}
</script>

<style lang="scss">

</style>