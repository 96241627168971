<template>
  <div class="cart-page-pay-options">
    <ul class="cart-tabs__list">
      <li class="cart-tabs__item"
          data-cursor-expand
          :class="{'_active': checkPayAfterConfirm}"
      >
        <a href="javascript:void(0);"
           class="cart-page-pay-options__link cart-tabs__link"
           title="Оплатить после подтверждения"
           @click="setPayAfterConfirm"
        >
          <span class="cart-tabs__text text__cart-point">
            Оплатить после подтверждения
          </span>
        </a>
      </li>

      <li class="cart-tabs__item"
          data-cursor-expand
          :class="{'_active': checkPayNow}"
      >
        <a href="javascript:void(0);"
           class="cart-page-pay-options__link cart-tabs__link"
           title="Оплатить сразу"
           @click="setPayNow"
        >
          <span class="cart-tabs__text text__cart-point">
            Оплатить сразу
          </span>
        </a>
      </li>
    </ul>

    <div class="cart-page-pay-options__text-wrap">
      <div class="cart-page-pay-options__text-help"
           :class="{'_active': checkPayAfterConfirm}"
           v-if="checkPayAfterConfirm"
      >
        После оформления заказа с вами свяжется менеджер для подтверждения всех деталей и согласования оплаты.
      </div>

      <div class="cart-page-pay-options__text-help"
           :class="{'_active': checkPayNow}"
           v-if="checkPayNow"
      >
        После оформления заказа вы будете перенаправлены на страницу онлайн-оплаты или оформления рассрочки,
        затем с вами свяжется менеджер для уточнения всех деталей
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "pinia";
import {orderStore} from "../../store/order.store";

export default {
  name: "TheCartPayOptions",
  computed: {
    checkPayAfterConfirm() {
      return this.isPayAfterConfirm();
    },
    checkPayNow() {
      return this.isPayNow();
    }
  },
  methods: {
    ...mapActions(orderStore, [
        'isPayAfterConfirm',
        'isPayNow',
        'setPayOption',
    ]),
    setPayAfterConfirm() {
      this.setPayOption(10);
    },
    setPayNow() {
      this.setPayOption(20);
    }
  }
}
</script>