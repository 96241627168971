<template>
  <div class="cart-preview-modal line-form line-form_modal line-form_order line-form_light">
    <div class="container container_spacer">
      <div class="cart-preview-modal__inner">
        <div class="line-form__form-name h3">
          Корзина
        </div>

        <ul class="cart-preview-modal__list grid-block">
          <li class="cart-preview-modal__item" v-for="item in items" v-show="!loading">
            <div class="line-form__order-image-wrap">
              <img :src="item.imagePath"
                   :alt="item.buyable.product.name"
                   class="cart-preview-modal__image"
                   v-if="item.imagePath"
              >
            </div>
            <div class="line-form__order-title-wrap">
              <div class="line-form__order-title h4">
                {{ item.buyable.product.title }}
              </div>
              <div class="line-form__order-config-title">
                {{ item.buyable.name }} {{ item.buyable.description }}
              </div>

              <div class="line-form__total-field-wrap">
                <div class="line-form__total-field total-field">
                    <a href="javascript:void(0);"
                       class="total-field__dec"
                       @click="decreaseItem(item)"
                       data-goal-yandex-click="tovar_minus"
                       :data-goal-vk-click="`tovar_minus|${item.buyable.product.id}`"
                       data-goal-google-click="tovar_minus#click"
                    >
                    </a>
                    <input type="number" class="total-field__input" :value="item.quantity" min="1" disabled>
                    <a href="javascript:void(0);"
                       class="total-field__inc"
                       @click="increaseItem(item)"
                       data-goal-yandex-click="tovar_plus"
                       :data-goal-vk-click="`tovat_plus|${item.buyable.product.id}`"
                       data-goal-google-click="tovar_plus#click"
                    >
                    </a>
                </div>

                <div class="line-form__order-price h4">
                  {{ price(item.price) }} ₽
                </div>
              </div>
            </div>

            <div class="line-form__order-chars">
              <ul class="line-form__chars-list">
                <li class="line-form__order-char" v-for="(value, key) in item.data.humanReadable.chars">
                  <div class="line-form__char-name">
                    {{ key }}
                  </div>
                  <div class="line-form__char-value">
                    {{ value }}
                  </div>
                </li>
              </ul>

              <div class="line-form__close">
                <a :href="`/catalog/${item.buyable.categorySlug}/${item.buyable.productSlug}`"
                   class="cart-preview-modal__change link link__text-with-arrow _left"
                   title="Выбрать другую"
                   data-cursor-expand
                >
                  <span class="link__text-with-arrow-text-wrap">
                    Выбрать другую
                  </span>
                  <span class="link__text-with-arrow-icon">
                    <Arrow/>
                  </span>
                </a>

                <a href="javascript:void(0);"
                   title="Удалить"
                   class="cart-preview-modal__remove button button__close _icon"
                   data-cursor-expand
                   @click="remove(item.key)"
                   data-goal-yandex-click="click_delete_tovar"
                   :data-goal-vk-click="`click_delete_tovar|${item.buyable.product.id}`"
                   data-goal-google-click="delete_tovar#click"
                >
                  <span class="button__close-text-wrap">
                    Удалить
                  </span>
                  <span class="cart-preview-modal__remove-icon button__close-icon-wrap"></span>
                </a>
              </div>
            </div>
          </li>
        </ul>

        <div class="cart-preview-modal__description-wrap">
          <div class="cart-preview-modal__description text__small-cart">
            После оформления заказа будет направлен эскиз товара в выбранной конфигурации
          </div>
        </div>

        <div class="cart-preview-modal__bottom-wrap">
          <div class="cart-preview-modal__total-info-wrap">
            <div class="cart-preview-modal__items-count text__main-big">
              {{ items.length }} {{ plural }}
            </div>

            <div class="cart-preview-modal__amount text__cart-point">
              На сумму {{ price(amount) }} ₽
            </div>
          </div>

          <a href="/cart"
             title="Оформить заказ"
             class="cart-preview-modal__button button button__big"
             data-cursor-expand
             data-goal-yandex-click="click_oformit_zakaz_pop_up"
             data-goal-vk-click="click_oformit_zakaz_pop_up"
             data-goal-google-click="oformit_zakaz_pop_up#click"
          >
            <span class="button__button-big-label-wrap">
                <span class="button__button-big-label">
                    Оформить заказ
                </span>
            </span>
            <span class="button__button-big-label-anim">
                Оформить заказ
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapWritableState} from "pinia";
import {cartStore} from "../../store/cart.store";
import Arrow from "../icons/Arrow";

export default {
  name: "CartPreviewModal",
  components: {Arrow},
  computed: {
    ...mapState(cartStore, [
      'checkout',
      'loading'
    ]),
    items() {
      return this.checkout ? this.checkout.items : [];
    },
    amount() {
      return this.checkout ? this.checkout.total : 0;
    },
    plural() {
      let num = this.items.length;
      let forms = ['товар', 'товара', 'товаров'];
      if (num === 0) {
        return forms[2];
      }

      let value = num % 100;

      if (value === 0) {
        return forms[2];
      }

      if (value > 10 && value < 20) {
        return forms[2];
      } else {
        let value = num % 10;
        if (value === 1) {
          return forms[0];
        } else if (value > 1 && value < 5) {
          return forms[1];
        } else {
          return forms[2];
        }
      }
    }
  },
  methods: {
    ...mapActions(cartStore, [
      'fetchCheckout',
      'increase',
      'decrease',
      'remove'
    ]),
    decreaseItem(item) {
      if (item.quantity - 1 <= 0) {
        this.remove(item.key);
      }
      this.decrease(item.key, 1);
    },
    increaseItem(item) {
      this.increase(item.key, 1);
    },
    price(price) {
      return new Intl.NumberFormat('ru-RU', {style: 'decimal'}).format(price);
    }
  },
  mounted() {
    this.fetchCheckout();
  },
}
</script>

<style lang="scss">

</style>