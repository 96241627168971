import Action from "./action";

class SlideWithFriends {
  constructor(index, slide, friends) {
    this._index = index;
    this._slide = slide;
    this._friends = friends ? friends : [];
  }

  get index() {
    return this._index;
  }

  get slide() {
    return this._slide;
  }

  get friends() {
    return this._friends;
  }

  addFriend(friendElement) {
    return this._friends.push(friendElement);
  }
}

class ActionWithState {
  constructor(action) {
    this._action = action;
    this._status = 'pending'; // play / done
    this._endTime = action.duration + action.delay;
  }

  get action() {
    return this._action;
  }

  setDone() {
    this._status = 'done';
  }

  setPlay() {
    this._status = 'play';
  }

  get isPlay() {
    return this._status === 'play';
  }

  get isDone() {
    return this._status === 'done';
  }

  get isPending() {
    return this._status === 'pending';
  }

  get endTime() {
    return this._endTime;
  }

  get startTime() {
    return this._action.delay;
  }

  get easingIn() {
    return this._action.easingIn;
  }

  get easingOut() {
    return this._action.easingOut;
  }
}

export default class RSlider {
  constructor(element, options) {
    this.element = element;
    this.name = element.dataset.rslider ? element.dataset.rslider : options.name;
    this.currentIndex = element.dataset.currentIndex ? parseInt(element.dataset.currentIndex) : options.currentIndex;
    if (!this.currentIndex) {
      this.currentIndex = 0;
    }

    this.currentAnimationFrame = null;
    this.currentTimeline = null;
    this.currentTimelineTimerOffset = null;
    this.currentSlideIn = null;
    this.currentSlideOut = null;
    this.playTimestamp = false;

    // Для отслеживания свайпов
    this.touchStartX = null;
    this.touchStartY = null;

    this.options = Object.assign({}, {
      hasReverse: true,
      onStart: () => {},
      timeline: [],
      timelineReverse: [],
      swipe: true
    }, element.dataset, options);

    this.prevElements = this.options.prevElements ? this.options.prevElements :
      document.querySelectorAll(`[data-rslider-prev="${this.name}"]`);
    this.nextElements = this.options.nextElements ? this.options.nextElements :
      document.querySelectorAll(`[data-rslider-next="${this.name}"]`);

    this.slides = [];
    this.init();
    this.start();
  }

  start() {
    const slideWithFriends = this.slides[this.currentIndex];
    this.options.onStart({
      slideIn: slideWithFriends.slide,
      slideFriendsIn: slideWithFriends.friends
    });
  }

  prev() {
    let nextIndex = this.currentIndex - 1;
    if (nextIndex < this.getMinIndex()) {
      nextIndex = this.getMaxIndex();
    }
    this.toSlide(nextIndex, this.options.timelineReverse);
  }

  next() {
    let nextIndex = this.currentIndex + 1;
    if (nextIndex > this.getMaxIndex()) {
      nextIndex = this.getMinIndex();
    }
    this.toSlide(nextIndex, this.options.timeline);
  }

  toSlide(index, forcedTimeline = null) {
    const slideIn = this.getSlideByIndex(index);
    const slideOut = this.getCurrentSlide();

    let timeline = this.options.timeline;
    if (index < this.currentIndex && this.options.timelineReverse && this.options.timelineReverse.length > 0) {
      timeline = this.options.timelineReverse;
    }

    if (forcedTimeline) {
      timeline = forcedTimeline;
    }

    this.startTimeline(timeline, slideIn, slideOut);
  }

  startTimeline(timeline, slideIn, slideOut) {
    if (this.playTimestamp === false) {
      this.playTimestamp = true;
    } else {
      return;
    }

    this.currentTimeline = timeline.map((action) => {
      return new ActionWithState(action);
    })

    this.currentTimelineTimerOffset = null;
    this.currentSlideIn = slideIn;
    this.currentSlideOut = slideOut;

    window.requestAnimationFrame(this.stepTimeline.bind(this));
  }

  stepTimeline(timestamp) {
    if (!this.currentTimelineTimerOffset) {
      this.currentTimelineTimerOffset = timestamp;
    }
    const left = timestamp - this.currentTimelineTimerOffset;

    let hasNextStep = false;
    this.currentTimeline.forEach((actionWithState) => {
      const startTime = actionWithState.startTime;
      const endTime = actionWithState.endTime;

      const progressInVarName = this.getProgressVariableName(actionWithState, 'in');
      const progressOutVarName = this.getProgressVariableName(actionWithState, 'out');

      if (left >= startTime && actionWithState.isPending) {
        // Start
        actionWithState.action.onStart({
          slideIn: this.currentSlideIn.slide,
          slideOut: this.currentSlideOut.slide,
          slideFriendsIn: this.currentSlideIn.friends,
          slideFriendsOut: this.currentSlideOut.friends
        })
        actionWithState.setPlay();
      }

      if (left >= endTime && !actionWithState.isDone) {
        // End
        actionWithState.action.onEnd({
          slideIn: this.currentSlideIn.slide,
          slideOut: this.currentSlideOut.slide,
          slideFriendsIn: this.currentSlideIn.friends,
          slideFriendsOut: this.currentSlideOut.friends
        });

        this.setCustomVariableToInSlide(progressInVarName, null);
        this.setCustomVariableToOutSlide(progressOutVarName, null);
        actionWithState.setDone();
      }

      if (actionWithState.isPlay) {
        const progress = (left - startTime) / endTime;

        const inValue = actionWithState.easingIn(progress);
        const outValue = actionWithState.easingOut(progress);

        this.setCustomVariableToInSlide(progressInVarName, inValue);
        this.setCustomVariableToOutSlide(progressOutVarName, outValue);
      }

      if (!actionWithState.isDone) {
        hasNextStep = true;
      }
    });

    if (hasNextStep) {
      window.requestAnimationFrame(this.stepTimeline.bind(this));
    } else {
      this.endTimeline()
    }
  }

  endTimeline() {
    // switch current slide
    this.currentIndex = this.currentSlideIn.index;
    this.currentSlideIn = null;
    this.currentSlideOut = null;
    this.currentTimeline = null;
    this.playTimestamp = false;
    this.currentTimelineTimerOffset = null;
  }

  getProgressVariableName(actionWithState, direction) {
    const nameParts = ['--rslider'];
    if (actionWithState.action.name) {
      nameParts.push(actionWithState.action.name.toLowerCase());
    }
    if (direction) {
      nameParts.push(direction);
    }
    nameParts.push('progress');
    return nameParts.join('-');
  }

  setCustomVariableToInSlide(name, value) {
    if (value === null) {
      this.currentSlideIn.slide.style.removeProperty(name);
      this.currentSlideIn.friends.forEach((friend) => {
        friend.style.removeProperty(name);
      });
    } else {
      this.currentSlideIn.slide.style.setProperty(name, value);
      this.currentSlideIn.friends.forEach((friend) => {
        friend.style.setProperty(name, value);
      });
    }
  }

  setCustomVariableToOutSlide(name, value) {
    if (value === null) {
      this.currentSlideOut.slide.style.removeProperty(name);
      this.currentSlideOut.friends.forEach((friend) => {
        friend.style.removeProperty(name);
      });
    } else {
      this.currentSlideOut.slide.style.setProperty(name, value);
      this.currentSlideOut.friends.forEach((friend) => {
        friend.style.setProperty(name, value);
      });
    }
  }

  getCurrentSlide() {
    return this.getSlideByIndex(this.currentIndex);
  }

  getSlideByIndex(index) {
    for(let key in this.slides) {
      const slideWithFriends = this.slides[key];
      if (slideWithFriends.index === index) {
        return slideWithFriends;
      }
    }
    return null;
  }

  getMaxIndex() {
    let index = 0;
    this.slides.forEach((slide) => {
      if (slide.index > index) {
        index = slide.index;
      }
    });
    return index;
  }

  getMinIndex() {
    let index = null;
    this.slides.forEach((slide) => {
      if (slide.index < index || index === null) {
        index = slide.index;
      }
    });
    return index;
  }

  touchStart(e) {
    this.touchStartX = e.changedTouches[0].screenX;
    this.touchStartY = e.changedTouches[0].screenY;
  }

  touchEnd(e) {
    const startX = this.touchStartX;
    const startY = this.touchStartY;
    const endX = e.changedTouches[0].screenX;
    const endY = e.changedTouches[0].screenY;
    const diffX = endX - startX;
    const diffY = endY - startY;

    if (Math.abs(diffY) > Math.abs(diffX)) {
      // Человек скроллил больше вверх/вниз, чем вправо/влево
      // Ничего не делаем (ну, пока)
      return;
    }

    // Барьер, до которого мы не ослеживаем свайпы
    // Чтобы избажать ложных свайпов при нажатиях на кнопки например
    const threshold = 10;
    if (Math.abs(diffX) < threshold) {
      return;
    }

    e.preventDefault();
    if (diffY > 0) {
      this.next();
    } else {
      this.prev();
    }
  }

  init() {
    const slidesWithFriends = [];

    this.element.querySelectorAll('[data-rslide]').forEach((slide, index) => {
      slidesWithFriends.push(new SlideWithFriends(index, slide));
    });

    const friendsContainers = document.querySelectorAll(`[data-rslider-friends-for="${this.name}"]`);
    friendsContainers.forEach((friendsContainer) => {
      friendsContainer.querySelectorAll('[data-rslide-friend]').forEach((friend, index) => {

        /** @var slideWithFriends SlideWithFriends */
        slidesWithFriends.forEach((slideWithFriends) => {
          if (slideWithFriends.index === index) {
            slideWithFriends.addFriend(friend);
          }
        })
      })
    });

    this.slides = slidesWithFriends;

    if (this.prevElements.length) {
      this.prevElements.forEach((prev) => {
        prev.addEventListener('click', (e) => {
          e.preventDefault();
          this.prev();
        });
      });
    }

    if (this.nextElements) {
      this.nextElements.forEach((next) => {
        next.addEventListener('click', (e) => {
          e.preventDefault();
          this.next();
        });
      });
    }

    if (this.options.swipe) {
      this.element.addEventListener('touchstart', (e) => {
        this.touchStart(e);
      });
      this.element.addEventListener('touchend', (e) => {
        this.touchEnd(e);
      });

      // Выключаем горизонталный скролл
      this.element.style.setProperty('touch-action', 'pan-y');
    }
  }

  static action({name, duration, delay, onStart, onEnd, easingIn, easingOut}) {
    return new Action(name, duration, delay, onStart, onEnd, easingIn, easingOut);
  }
}