// в конструктор передать экземпляр Swiper
// const slider = new Swiper(sliderEl, {});
// new CursorProgressBar(slider);
export class CursorProgressBar {
    constructor(slider) {
        this.slider = slider;
        this.sliderEl = this.slider.$el[0];
        this.progressBarEl = undefined;
        this.progressPosition = {
            current: 0,
            last: 0,
        }
        this.ease = 0.05;

        this.eventListeners();
        this.update();
    }

    eventListeners() {
        this.sliderEl.addEventListener('mouseenter', () => {
            if (!this.progressBarEl) {
                this.progressBarEl = document.querySelector('[data-cursor] [data-drag] svg');
            }
        });
    }

    update = () => {
        if (this.progressBarEl) {
            this.updateCursorProgressBar();
        }

        window.requestAnimationFrame(this.update.bind(this));
    }

    updateCursorProgressBar = () => {
        if (!this.circle) {
            this.circle = this.progressBarEl.getElementById('progress-bar');
            this.circleDasharray = parseInt(getComputedStyle(this.circle).strokeDasharray, 10);
        }

        if (this.slider.progress < 0) {
            this.progressPosition.last = 0;
        } else if (this.slider.progress > 1) {
            this.progressPosition.last = 1;
        } else {
            this.progressPosition.last = this.slider.progress;
        }

        this.progressPosition.current = (1 - this.ease) * this.progressPosition.current + this.ease * this.progressPosition.last;
        this.circle.style.strokeDashoffset = this.circleDasharray - this.circleDasharray * this.progressPosition.current;
    }
}