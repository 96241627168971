<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" viewBox="0 0 30 30"><path stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5" stroke-width="2" d="M10 23.75a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm12.5 0a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/><path stroke="#000" stroke-linecap="round" stroke-width="2" d="M12.563 21.25h6.187V5.75A.75.75 0 0 0 18 5H1.25m5.813 16.25H4.5a.75.75 0 0 1-.75-.75v-6.125"/><path stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.5 10h5"/><path stroke="#000" stroke-linecap="round" stroke-width="2" d="M18.75 10h7.012a.75.75 0 0 1 .686.445l2.237 5.035a.75.75 0 0 1 .065.304V20.5a.75.75 0 0 1-.75.75h-2.375m-6.875 0H20"/></svg>
</template>

<script>
export default {
  name: "DeliveryIcon"
}
</script>

<style lang="scss">

</style>