import {onDomReady} from "../../components/dynamic/observer";

onDomReady(() => {
    $("#order_delivery").suggestions({
        token: "2817ccafb282ced493938581f672dbbec840fa9a",
        type: "ADDRESS",
        /* Вызывается, когда пользователь выбирает одну из подсказок */
        onSelect: function(suggestion) {
            console.log(suggestion);
        }
    });
})