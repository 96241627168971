function initMultyMapYandex() {
  document.querySelectorAll('[data-multy-map]').forEach(function initMapElement(mapElement) {
    const myMap = createMap(mapElement);
    const mark = mapElement.dataset.mark;
    const points = document.querySelectorAll('[data-service-point]');
    if (!points || !mark) {
      return;
    }

    points.forEach( point => {
      const myPlacemark = createPlacemark(point, mark);
      if (myPlacemark) {
        bindPlacemark(point, myPlacemark, myMap);
        myMap.geoObjects.add(myPlacemark);
      }
    })
  });
  // bindTogglers();
}

function bindTogglers() {
  const mapBlock = document.querySelector('[data-map-block]');
  const opener = mapBlock.querySelector('[data-open-cart]');
  const closer = mapBlock.querySelector('[data-close-cart]');
  opener.addEventListener('click',(e) => {
    e.preventDefault();
    mapBlock.classList.add('_map-opened');
    opener.parentNode.classList.add('_active');
    closer.parentNode.classList.remove('_active');
  });

  closer.addEventListener('click', (e) => {
    e.preventDefault();
    mapBlock.classList.remove('_map-opened');
    closer.parentNode.classList.add('_active');
    opener.parentNode.classList.remove('_active');
  });
}

function bindPlacemark(pointElement, placemark, myMap) {
  pointElement.addEventListener('click', (e) => {
    e.preventDefault();
    placemark.balloon.open();

    myMap.setCenter([pointElement.dataset.lat, pointElement.dataset.lng])
  });

  placemark.balloon.events.add('open', () => {
    // pointElement.classList.add('_active');
    placemark.balloon.close()
  });

  // placemark.balloon.events.add('close', () => {
  //   pointElement.classList.remove('_active');
  // });
}

function createPlacemark(pointElement, mark) {
  const lat = parseFloat(pointElement.dataset.lat);
  const lng = parseFloat(pointElement.dataset.lng);
  if (!lat || !lng) {
    return null;
  }

  return new ymaps.Placemark(
    [lat, lng],
    {
      hintContent: pointElement.title,
      balloonContentHeader: pointElement.dataset.type,
      balloonContent: pointElement.title
    },
    {
      iconLayout: 'default#image',
      iconImageHref: mark,
      iconImageSize: [44, 52],
      iconImageOffset: [-16, -57]
    });
}

function createMap(mapElement) {
  const myMap =  new ymaps.Map(mapElement, {
    center: [parseFloat(mapElement.dataset.lat), parseFloat(mapElement.dataset.lng)],
    zoom: mapElement.dataset.zoom,
    controls: ['zoomControl', 'fullscreenControl']

  }, {
    searchControlProvider: 'yandex#search'
  });

  myMap.behaviors.disable('scrollZoom');
  if (window.innerWidth <= 1024) {
    myMap.behaviors.disable('drag');
  }

  return myMap;
}

window.addEventListener('load', () => {
  if (window.ymaps) {
    ymaps.ready(initMultyMapYandex);
  }
});