import IMask from "imask";
import axios from "axios";
import {onDomChanges} from "../../components/dynamic/observer";
import {singleInit} from "../../components/dynamic/bind";

onDomChanges(() => {
  document.querySelectorAll('[data-phone-field]').forEach((input) => {
    singleInit(input, 'initialized',  () => {
      const mask = IMask(input, {
        mask: '+{7} (000) 000-00-00'
      });

      mask.on("complete", () => {
        if (input.dataset.leadUrl) {
          axios.post(input.dataset.leadUrl, {
            phone: input.value
          });
        }
      });
    })
  })
});

function makeMask(input) {
  input.dataset.initialized = 'true';

  const dispatchMask = IMask(input, {
    mask: '+{7} (000) 000-00-00',
    prepare: function (str) {
      if (!this._value && str === '8') {
        return '7'
      }
      return str;
    },
  });

  dispatchMask.on('complete', () => {
    if (input.dataset.leadUrl) {
      axios.post(input.dataset.leadUrl, {
        phone: input.value
      });
    }
  });
}

export {makeMask};