/**
 * Анимируем блоки на странице в тот момент, когда блок попадает в область видимости пользователя
 *
 * Пример:
 * <div data-need-animation> </div>
 *
 * В тот момент, когда пользователь доскроллит до этого блока, блоку навесится класс animate
 *
 * Настраиваемые параметры в data-attributes:
 *
 * data-delay - задержка перед навешиванием класса animate, в миллисекундах
 * Пример: <div data-need-animation data-delay="500"> </div>
 *
 * data-fully - навешивать класс animate только когда блок появится на странице целиком (1 или 0)
 * (подходит для небольших в высоту блоков)
 * Пример: <div data-need-animation data-fully="1"> </div>
 *
 * data-ratio - при пересечении какой части экрана верхней линией блока навесится класс active.
 * Например, указанное значение - 2. Делим 100% высоты экрана на 2 и получаем - 50%. При достижении 50% экрана по верху блока класс active будет навешен.
 * Еще пример: Указанное значение - 3. Делим 100% высоты экрана на 3 и получаем - 33%. При достижении 33% экрана по верху блока класс active будет навешен.
 *
 * Плюшки:
 *
 * Собирает элементы со страницы для проверки классов не чаще 1 раза в секунду,
 * то есть сильно сокращает нагрузку на браузер, чем если бы на каждое событие scroll вызывать поиск элементов
 *
 */
import {onDomChanges, onDomReady} from "../../components/dynamic/observer";
import Accordion from "../../components/accordion/accordion";

class NeedAnimation {
  elements = [];
  lastCollect = undefined;
  pageYOffset = 0;
  lastPageYOffset = undefined;
  filterChanged = false;

  constructor() {
    this.lastCollect = new Date();

    this.init();
  }

  init() {
    this.onResize();
    this.eventListeners();
    this.collectElements();
    this.check();
  }

  eventListeners() {
    const instance = this;
    window.addEventListener('resize', () => instance.onResize());

    document.addEventListener('scroll', (e) => {
        // instance.pageYOffset = e.detail.offsetY;
        instance.pageYOffset = window.pageYOffset;
      this.filterChanged = false;
    });

    document.addEventListener('DOMContentMutated', () => {
      instance.collectElements();
    });

    document.addEventListener('filter:values-changed', () => {
      this.filterChanged = true;
    });
  }

  onResize() {
    this.windowHeight = window.innerHeight;
  }

  check() {
    this.safeCollect();
    const scrollTop = this.pageYOffset;
    const windowHeight = this.windowHeight;
    const scrolled = scrollTop + windowHeight;

    if (this.pageYOffset !== this.lastPageYOffset || this.filterChanged) {
      this.elements.forEach((element) => {

        const bounds = element.getBoundingClientRect();
        const offsetTop = bounds.top + scrollTop;

        if (element.className.indexOf('animate') === -1 && !element.dataset.transit) {
          const delay = element.dataset.delay || 0;
          const ratio = element.dataset.ratio || 2;
          const fully = element.dataset.fully || 0;
          const outDelay = element.dataset.outDelay || 0;
          let animate = false;

          if (fully) {
            if ((bounds.height + offsetTop) < scrolled) {
              animate = true;
            }
          } else if ((bounds.top) < (windowHeight / ratio)) {
            animate = true;
          }

          if (animate) {
            element.dataset.transit = 'on';
            setTimeout(() => {
              element.classList.add('animate');
              element.classList.remove('need-animation');
            }, delay);
            setTimeout(() => {
              element.classList.add('animate-out');
            }, outDelay);
          }
        }
      });

      this.lastPageYOffset = this.pageYOffset;
    }

    window.requestAnimationFrame(this.check.bind(this));
  }

  safeCollect() {
    const now = new Date();
    if ((now - this.lastCollect) > 1000) {
      this.collectElements();
    }
  }

  collectElements() {
    this.elements = document.querySelectorAll('[data-need-animation]:not(.animate)');
    this.lastCollect = new Date();
  }
}

onDomReady(() => {
  setTimeout(() => {
    new NeedAnimation();
  }, 0)
});
