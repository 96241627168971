<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="28" fill="none"><path stroke="#A39F9D" d="m24.177 18.375-6.4-.146 2.754 6.599-3.6 1.502-2.753-6.598-4.397 4.652.444-19.184 13.952 13.175Z"/><circle cx="3.9" cy="3.9" r="3.4" stroke="#A39F9D"/><circle cx="3.9" cy="13" r="3.4" stroke="#A39F9D"/><path fill="#A39F9D" fill-rule="evenodd" d="M25.066 15.532a3.9 3.9 0 0 0-5.664-5.349l.729.688a2.9 2.9 0 0 1 4.207 3.973l.728.688Z" clip-rule="evenodd"/><circle cx="22.1" cy="3.9" r="3.4" stroke="#A39F9D"/><path fill="#A39F9D" fill-rule="evenodd" d="M15.861 6.55a3.9 3.9 0 0 0-5.67-5.355l.727.687a2.9 2.9 0 0 1 4.217 3.982l.726.686Z" clip-rule="evenodd"/></svg>
</template>

<script>
export default {
  name: "ArrowWithCircles"
}
</script>

<style lang="scss">

</style>