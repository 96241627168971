require('./scroll-slider');
require('./swipe-slider');
require('./rslider');
require('./product-page-main-slider');
require('./product-page-collection-slider');
require('./product-page-viewed-slider');
require('./product-card-slider');
require('./example-slider');
require('./hero-slider');
require('./reviews-common-slider');
require('./product-material-slider');