import {defineStore} from "pinia";
import catalogService from "../services/catalog.service";

export const productOrderStore = defineStore('productOrder', {
    state: () => (
        {
            product: null,
            state: {
                offerId: null,
                characteristics: [],
            },
            options: null,
        }
    ),
    actions: {
        async getOptions(data) {
            try {
                const result = await catalogService.getOptions(data);
                this.product = result.data.product;
                this.options = result.data.options;

                this.state.offerId = this.options.offers[0].id;
                if (this.options.characteristics) {
                    this.options.characteristics.forEach((characteristic) => {
                        this.state.characteristics.push({
                            charId: characteristic.id,
                            charValue: characteristic.characteristicValues[0].id
                        })
                    });
                }

                setTimeout(() => {
                    document.dispatchEvent(new Event('getProductOptions'));
                }, 250);
            } catch (e) {
                console.log(e);
            }
        },
    },
})