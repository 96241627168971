<template>
  <!--  offers  -->
  <div class="product-order-config" v-if="options" data-accordion-container>
    <div class="product-order-config__offers-block">
      <div class="product-order-config__title-wrap">
        <div class="product-order-config__title">
          выберите конфигурацию
        </div>

        <div class="white-small-label">
          <span class="white-small-label__text">
              Влияет на цену
          </span>
        </div>
      </div>

      <div class="product-order-config__items">
        <TheProductOrderConfigAccordion :title="'Размер'"
                                        :values="offers"
                                        :model-value="state.offerId"
                                        @mounted="handleMountedAccordionElement"
                                        @update:modelValue="updateOfferState"
                                        class="product-order-config__item"/>
      </div>
    </div>

    <!--  options  -->
    <div class="product-order-config__options-block">
      <div class="product-order-config__title-wrap">
        <div class="product-order-config__title">
          выберите отделку
        </div>

        <div class="white-small-label">
          <span class="white-small-label__text">
              Не влияет на цену
          </span>
        </div>
      </div>

      <div class="product-order-config__items">
        <div v-for="char in characteristics" :key="`product-char-${char.id}`" :id="`product-char-${char.id}-radio`">
          <TheProductOrderConfigAccordion :title="char.title"
                                          :char-id="char.id"
                                          :values="char.characteristicValues"
                                          :model-value="getCharModelValueById(char.id)"
                                          @mounted="handleMountedAccordionElement"
                                          @update:modelValue="updateCharState($event, char.id)"
                                          class="product-order-config__item"/>
        </div>
      </div>
    </div>

  <!-- submit -->
    <div class="product-order-config__button-wrap" data-order-button-wrap>
      <div class="product-order-config__button-container">
        <a :href="`/cart-preview-modal`"
           title="Купить"
           class="product-order-config__button button button__big"
           data-modal
           data-theme="light"
           data-cursor-expand
           data-order-button
           :data-id="`${state.offerId}`"
           data-quantity="1"
           data-buyable="App\Catalog\Entity\Offer"
           :data-product-id="`${id}`"
           :data-category="`${category}`"
           @click="addToCart"
           data-goal-yandex-click="click_kupit"
           :data-goal-vk-click="`click_kupit#${priceToGoal}|${id}`"
           data-goal-google-click="kupit_kartochka#click"
           data-ecommerce-product-page
           :data-ecommerce="dataToEcomm"
        >
          <span class="button__button-big-label-wrap">
            <span class="button__button-big-label">
              <span class="product-order-config__button-price-wrap">
                <span class="product-order-config__button-price" v-show="price">
                  {{ price }}
                </span>
                <span class="product-order-config__button-price-old" v-show="priceOld">
                  {{ priceOld }}
                </span>
              </span>
              <span class="product-order-config__button-dash">|</span>
              <span class="product-order-config__button-title">{{ buttonName }}</span>
            </span>
          </span>
          <span class="button__button-big-label-anim">
            <span class="product-order-config__button-price" v-show="price">
              {{ price }}
            </span>
            <span class="product-order-config__button-price-old" v-show="priceOld">
              {{ priceOld }}
            </span>
            <span class="product-order-config__button-dash">|</span>
            <span class="product-order-config__button-title">{{ buttonName }}</span>
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapWritableState} from "pinia";
import {productOrderStore} from "../../store/catalog.store";
import TheProductOrderConfigAccordion from "./TheProductOrderConfigAccordion.vue";
import BaseFieldRadio from "./ProductOfferConfigFieldRadio.vue";
import axios from "axios";
import {cartStore} from "../../store/cart.store";

export default {
  name: "TheProductOrderConfig",
  components: {
    BaseFieldRadio,
    TheProductOrderConfigAccordion
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      mountedAccordionElements: 0,
      buttonName: 'Купить',
    }
  },
  computed: {
    ...mapWritableState(productOrderStore, [
      'productId',
      'state'
    ]),
    ...mapState(productOrderStore, [
      'options',
      'product'
    ]),
    characteristics() {
      return this.options.characteristics;
    },
    offers() {
      return this.options.offers;
    },
    category() {
      return this.options.category;
    },
    currentOffer() {
      if (!this.offers || !this.offers.find(item => item.id === this.state.offerId)) {
        return null;
      }
      return this.offers.find(item => item.id === this.state.offerId);
    },
    price() {
      if (!this.currentOffer) {
        return null;
      }
      return this.currentOffer.price;
    },
    priceToGoal() {
      if (!this.currentOffer) {
        return null;
      }
      return parseFloat(this.price.replace(/[\s, ₽]/g, ''));
    },
    priceOld() {
      if (!this.currentOffer) {
        return null;
      }
      return this.currentOffer.priceOld;
    },
    dataToEcomm() {
      let charString = '';
      this.state.characteristics.forEach((char) => {
        const charName = this.characteristics.find(item => item.id === char.charId).title;
        const charValues = this.characteristics.find(item => item.id === char.charId).characteristicValues;
        const charValue = charValues.find(charValue => charValue.id === char.charValue).title;

        charString += charName + ': ' + charValue + '\n';
      });

      const price = parseFloat(this.price.replace(/[\s, ₽]/g, ''));

      return JSON.stringify({
        id: this.product.id,
        name: this.product.title + ' ' + this.currentOffer.title,
        category: this.category,
        description: this.currentOffer.description,
        chars: charString,
        price: price,
        quantity: 1,
      });
    },
  },
  methods: {
    updateButtonName() {
      if (window.flagsMap['buy_button'] && window.flagsMap['buy_button'] === 'to_cart') {
        this.buttonName = 'В корзину';
      }
    },
    ...mapActions(productOrderStore, [
        'getOptions',
    ]),
    ...mapActions(cartStore, [
      'add',
    ]),
    updateOfferState(value) {
      this.state.offerId = value;
    },
    updateCharState(value, id) {
      this.state.characteristics.find(item => item.charId === id).charValue = value;
    },
    getCharModelValueById(id) {
      return this.state.characteristics.find(item => item.charId === id).charValue ?? null;
    },
    handleMountedAccordionElement() {
      if (this.characteristics) {
        this.mountedAccordionElements++;

        if (this.mountedAccordionElements === this.characteristics.length) {
          setTimeout(() => {
            document.dispatchEvent(new Event('DOMContentMutated'));
            document.dispatchEvent(new Event('ProductOrderConfigInit'));
          }, 250);
        }
      }
    },
    addToCart(e) {
      const buyable = e.target.dataset.buyable;
      const id = parseInt(e.target.dataset.id, 10);
      const quantity = parseInt(e.target.dataset.quantity, 10);
      const productId = parseInt(e.target.dataset.productId, 10);
      const characteristics = this.state.characteristics;

      document.dispatchEvent(new CustomEvent('cartAdd', {
        detail: {
          buyable: buyable,
          id: id,
          quantity: quantity,
          element: e.target,
          characteristics: characteristics
        }
      }));

      this.add({
        buyable,
        id,
        quantity,
        productId,
        characteristics
      });
    },
  },
  mounted() {
    this.getOptions({productId: this.id});
    document.addEventListener('onInitExperiments', this.updateButtonName);
  }
}
</script>

<style lang="scss">

</style>