import {onDomReady} from "../../components/dynamic/observer";

class InstallmentHint {
    constructor(button) {
        this.button = button;
        this.hintBlock = undefined;
        this.closer = undefined;
        this.isMobile = false;
        this.checkMobile();
        this.init();
    }

    init() {
        if (!this.isMobile) {
            return;
        }

        this.hintBlock = document.querySelector('[data-installment-hint-block]');
        if (!this.hintBlock) {
            return;
        }

        this.closer = this.hintBlock.querySelector('[data-installment-hint-closer]');
        this.addListeners();
    }

    checkMobile() {
        this.isMobile = window.matchMedia('(max-width: 1023px)').matches;
    }

    addListeners() {
        window.addEventListener('resize', () => {
            this.onResize();
            this.init();
        });
        if (!this.isMobile) {
            return;
        }
        this.button.addEventListener('click', () => {
            if (!this.hintBlock.classList.contains('_opened')) {
                this.hintBlock.classList.add('_opened');
                document.body.style.overflow = 'hidden';
            }
        })
        this.closer.addEventListener('click', () => {
            if (this.hintBlock.classList.contains('_opened')) {
                this.hintBlock.classList.remove('_opened');
                document.body.style.overflow = 'auto';
            }
        })
    }

    onResize() {
        this.checkMobile();

        if (!this.isMobile) {
            this.button.addEventListener('click', () => {
                document.body.style.overflow = 'auto';
                if (this.hintBlock.classList.contains('_opened')) {
                    this.hintBlock.classList.remove('_opened');
                }
            });
        }
    }
}

onDomReady(() => {
    setTimeout(() => {
        const hintButton = document.querySelector('[data-installment-hint-button]');
        if (hintButton) {
            new InstallmentHint(hintButton);
        }
    }, 1000);
})