<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><circle cx="10" cy="10" r="10" fill="#fff"/><path stroke="#000" d="m5.428 9.857 3.368 3.547 5.775-6.857"/></svg>
</template>

<script>
export default {
  name: "CheckedIcon"
}
</script>

<style lang="scss">

</style>