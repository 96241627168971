<template>
  <div class="cart-block-heading" :class="blockClass">
    <div class="cart-block-heading__index">
      {{ index }}
    </div>

    <h3 class="cart-block-heading__title">
      {{ title }}
    </h3>
  </div>
</template>

<script>
export default {
  name: "CartBlockHeading",
  props: {
    blockClass: String,
    index: Number,
    title: String,
  }
}
</script>

<style lang="scss">

</style>