<template>
  <div class="product-order-config-accordion" data-accordion-element>
    <a href="javascript:void(0);"
       class="product-order-config-accordion__link"
       data-accordion-link
       v-if="currentValue"
       data-cursor-expand
    >
      <img class="product-order-config-accordion__link-image"
           :src="currentValue.imagePath"
           :alt="currentValue.title">

      <span class="product-order-config-accordion__link-text-wrap">
          <span class="product-order-config-accordion__link-title">
            {{ title }}
          </span>

          <span class="product-order-config-accordion__link-subtitle">
            {{ currentValue.title }} {{ currentValue.description }}
          </span>
        </span>

      <pencil-icon class="product-order-config-accordion__link-icon product-order-config-accordion__link-icon_closed"/>
      <arrow-with-circles class="product-order-config-accordion__link-icon product-order-config-accordion__link-icon_opened"/>
    </a>

    <div class="product-order-config-accordion__block" data-accordion-block>
      <div class="product-order-config-accordion__block-inner">
        <product-offer-config-field-radio v-for="value in values"
                                          :key="`product-offer-config-field-radio-${value.id}`"
                                          :char-id="charId"
                                          :value="value"
                                          v-model="modelValue"
                                          @update:modelValue="update"/>
      </div>
    </div>
  </div>
</template>

<script>
import PencilIcon from "../icons/PencilIcon.vue";
import {mapActions} from "pinia";
import {productOrderStore} from "../../store/catalog.store";
import ProductOfferConfigFieldRadio from "./ProductOfferConfigFieldRadio.vue";
import ArrowWithCircles from "../icons/ArrowWithCircles.vue";

export default {
  name: "TheProductOrderConfigAccordion",
  components: {ArrowWithCircles, ProductOfferConfigFieldRadio, PencilIcon},
  props: ['charId', 'title', 'modelValue', 'values'],
  emits: ['update:modelValue', 'mounted'],
  computed: {
    currentValue() {
      if (!this.values) {
        return null;
      }
      return this.values.find(item => item.id === this.modelValue);
    }
  },
  methods: {
    ...mapActions(productOrderStore, [
      'setState',
    ]),
    update(value) {
      this.$emit('update:modelValue', value);
    }
  },
  mounted() {
    if (this.modelValue) {
      this.$emit('mounted');
    }
  }
}
</script>

<style lang="scss">

</style>