<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" fill="none" viewBox="0 0 20 18"><path stroke="#fff" stroke-width="2" d="M0 9h18M10.5 1l8 8-8 8"/></svg>
</template>

<script>
export default {
  name: "ButtonArrow"
}
</script>

<style lang="scss">

</style>