import GLightbox from 'glightbox';
import {onDomReady} from "../../components/dynamic/observer";

onDomReady(() => {
  const gLightbox= new GLightbox();

  if (window.innerWidth <= 768) {
    gLightbox.on('open', () => {
      const imgNode = gLightbox.activeSlide.querySelector('img');
      const description = gLightbox.slideDescription;

      setTimeout(() => {
        description.setAttribute('style', `max-width: ${imgNode.offsetWidth}px !important;`);
      }, 150);
    });

    gLightbox.on('slide_after_load', ({ slideNode }) => {
      const imgNode = slideNode.querySelector('img');
      const description = slideNode.querySelector('.gslide-description');

      setTimeout(() => {
        description.setAttribute('style', `max-width: ${imgNode.offsetWidth}px !important;`);
      }, 150);
    });

    window.addEventListener('resize', () => {
      const imgNode = gLightbox.activeSlide.querySelector('img');
      const description = gLightbox.slideDescription;

      setTimeout(() => {
        description.setAttribute('style', `max-width: ${imgNode.offsetWidth}px !important;`);
      }, 150);
    });
  }
})