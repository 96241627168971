<template>
  <div class="cart-order-info">
    <ul class="cart-order-info__list">
      <li class="cart-order-info__item" v-for="(infoItem) in infoItems">
        <div class="cart-order-info__item-icon">
          <OrderInfoItemIcon/>
        </div>

        <div class="cart-order-info__item-title">
          {{ infoItem.title }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapActions, mapState} from "pinia";
import {orderInfoStore} from "../../store/orderInfo.store";
import OrderInfoItemIcon from "../icons/OrderInfoItemIcon.vue";
export default {
  name: "CartOrderInfo",
  components: {
    OrderInfoItemIcon,
  },
  computed: {
    ...mapState(orderInfoStore, [
      'infoItems'
    ]),
  },
  methods: {
    ...mapActions(orderInfoStore, [
      'fetchOrderInfoItems'
    ]),
  },
  mounted() {
    this.fetchOrderInfoItems();
  }
}
</script>