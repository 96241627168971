import {onDomChanges, onDomReady} from "../../components/dynamic/observer";

class Follower {
    constructor(selector) {
        selector.dataset.initialize = "true";

        this.container = selector;
        this.itemsContainer = this.container.querySelector('[data-follower-items-container]');
        this.items = Array.from(this.container.querySelectorAll('[data-follow-item]'));

        this.target = selector.querySelector('[data-follow-target-container]');
        if (!this.target) {
            console.log(`Follower: Could not found target element`);
            return;
        }
        this.images = Array.from(this.target.querySelectorAll('[data-follow-target-image]'));

        this.largeMinWidth = 1024;
        this.offsetY = 0;
        this.ease = 0.05;
        this.cursor = {
            x: 0,
            y: 0,
        };
        this.currentPosition = {
            x: 0,
            y: 0,
        };
        this.lastPosition = {
            x: 0,
            y: 0,
        };


        if (window.innerWidth >= this.largeMinWidth) {
        // this.bindObserveAreas();
            this.eventListeners();
            this.update();
        }
    }

    update() {
        const instance = this;

        instance.lerp();
        instance.setTargetY();
        instance.setTargetX();

        window.requestAnimationFrame(() => {
            instance.update();
        });
    }

    setTargetX() {
        this.target.style.left = `${this.currentPosition.x}px`;
    }

    setTargetY() {
        this.target.style.top = `${this.currentPosition.y}px`;
    }

    computedY() {
        this.lastPosition.y = this.cursor.y - this.target.offsetHeight / 2;
    }

    computedX() {
        this.lastPosition.x = this.cursor.x - this.target.offsetWidth / 2;
    }

    lerp() {
        this.currentPosition.x = (1 - this.ease) * this.currentPosition.x + this.ease * this.lastPosition.x;
        this.currentPosition.y = (1 - this.ease) * this.currentPosition.y + this.ease * this.lastPosition.y;
    }

    eventListeners() {
        const instance = this;

        window.addEventListener('mousemove', (e) => {
            instance.cursor.x = e.clientX;
            instance.cursor.y = e.clientY;
            this.computedY();
            this.computedX();
        });

        this.items.forEach(item => {
            item.addEventListener('mouseenter', () => {
                this.currentItem = item;
                this.images
                    .find(image => image.dataset.followTargetImage === item.dataset.followItem)
                    .classList.add('_active');
            });

            item.addEventListener('mouseleave', () => {
                this.images.find(image => {
                    if (image.dataset.followTargetImage === item.dataset.followItem) {
                        image.classList.remove('_active');
                        image.classList.add('_visible');
                    }
                });
            });
        });

        this.itemsContainer.addEventListener('mouseleave', () => {
            this.images.map(image => {
                if (image.dataset.followTargetImage === this.currentItem.dataset.followItem) {
                    image.style.zIndex = '10';
                }

                image.classList.add('_out');
            });
            setTimeout(() => {
                this.images.map(image => {
                    image.classList.remove('_out');
                    image.classList.remove('_visible');
                    image.style.zIndex = '';
                });
            }, 500);
        });
    }
}

function init() {
    let containers = document.querySelectorAll('[data-follower-container]:not([data-initialize="true"])');
    containers.forEach((item) => {
        const follower = new Follower(item);
    });
}

onDomReady(() => init());
onDomChanges(() => init());