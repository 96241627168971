<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14"><path stroke="#000" stroke-width="2" d="M12 5.738c0 .535-.244 1.266-.732 2.14-.476.851-1.123 1.734-1.79 2.544a32.665 32.665 0 0 1-1.978 2.18 32.665 32.665 0 0 1-1.978-2.18c-.667-.81-1.314-1.693-1.79-2.544C3.244 7.004 3 6.273 3 5.738 3 3.081 5.054 1 7.5 1S12 3.081 12 5.738Z"/><ellipse cx="7.499" cy="5.765" fill="#000" rx="1.571" ry="1.647"/></svg>
</template>

<script>
export default {
  name: "MapPoint"
}
</script>

<style lang="scss">

</style>