<template>
  <div class="cart-contacts">
    <CartBlockHeading :blockClass="'cart-page__block-heading'" :index="1" :title="'Контактная информация'"/>

    <ul class="cart-contacts__form line-form line-form_light">
      <li class="cart-contacts__field line-form__field _half" data-hide-custom-cursor>
        <div class="smart-label" data-smart-label>
          <input type="text"
                 id="order_name"
                 class="cart-contacts__input line-form__input"
                 v-model="name"
                 required="required"
          >

          <label for="order_name" class="cart-contacts__label smart-label__primary">
            Имя*
          </label>

          <label for="order_name" class="cart-contacts__label smart-label__secondary">
            Имя*
          </label>
        </div>
        <ItemErrors :errors="errors.name"/>
      </li>

      <li class="cart-contacts__field line-form__field _half" data-hide-custom-cursor>
        <div class="smart-label" data-smart-label>
          <input type="text"
                 id="order_last_name"
                 class="cart-contacts__input line-form__input"
                 v-model="lastName"
                 required="required"
          >

          <label for="order_last_name" class="cart-contacts__label smart-label__primary">
            Фамилия*
          </label>

          <label for="order_last_name" class="cart-contacts__label smart-label__secondary">
            Фамилия*
          </label>
        </div>
        <ItemErrors :errors="errors.lastName"/>
      </li>

      <li class="cart-contacts__field line-form__field _half" data-hide-custom-cursor>
        <div class="smart-label" data-smart-label>
          <input type="text"
                 id="order_phone"
                 class="cart-contacts__input line-form__input"
                 v-model="phone"
                 name="phone"
                 required="required"
                 ref="phone"
          >

          <label for="order_phone" class="cart-contacts__label smart-label__primary">
            Номер телефона*
          </label>

          <label for="order_phone" class="cart-contacts__label smart-label__secondary">
            Номер телефона*
          </label>
        </div>
        <ItemErrors :errors="errors.phone"/>
      </li>

      <li class="cart-contacts__field line-form__field _half" data-hide-custom-cursor>
        <div class="smart-label" data-smart-label>
          <input type="text"
                 id="order_email"
                 class="cart-contacts__input line-form__input"
                 v-model="email"
          >

          <label for="order_email" class="cart-contacts__label smart-label__primary">
            Почта
          </label>

          <label for="order_email" class="cart-contacts__label smart-label__secondary">
            Почта
          </label>
        </div>
        <div class="cart-contacts__optional">
          не обязательно
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapState, mapWritableState} from "pinia";
import {makeMask} from "../../../js/field/phone";

import ItemErrors from "./ItemErrors";
import CartBlockHeading from "../parts/CartBlockHeading";
import {orderStore} from "../../store/order.store";

export default {
  name: "TheCartContacts",
  computed: {
    ...mapWritableState(orderStore, [
        'name',
        'lastName',
        'phone',
        'email'
    ]),
    ...mapState(orderStore, [
        'errors'
    ])
  },
  components: {
    ItemErrors,
    CartBlockHeading,
  },
  mounted() {
    const phoneInput = this.$refs.phone;
    makeMask(phoneInput);
  },
}
</script>

<style lang="scss">

</style>