import {defineStore} from "pinia";
import orderService from "../services/order.service";
import {notificationStore} from "./notification.store";

export const orderStore = defineStore('order', {
    state: () => (
        {
            name: '',
            lastName: '',
            phone: '',
            email: '',
            receiving: 20,
            payment: 10,
            payOption: 10,
            comment: '',
            address: '',
            pickupPoint: 1,
            giftCard: '',
            errors: {
                name: [],
                lastName: [],
                phone: [],
                addressFilled: [],
                pickupPointSelected: [],
            },
            promocode: '',
            redirectBaseUrl: '/cart/success',
            redirectCreditUrl: '/cart/credit',
            redirectPaymentUrl: '/cart/pay',
            redirectInstallmentUrl: '/cart/installment',
        }
    ),
    actions: {
        setReceiving(type) {
            this.receiving = type;
        },
        setPickupPoint(point) {
            this.pickupPoint = point;
        },
        setPromocode(value) {
            this.promocode = value;
        },
        setPayOption(value) {
            this.payOption = value;
        },
        isDelivery() {
            return this.receiving === 20;
        },
        isPickup() {
            return this.receiving === 10;
        },
        isPayAfterConfirm() {
            return this.payOption === 10;
        },
        isPayNow() {
            return this.payOption === 20;
        },
        async send() {
            try {
                const result = await orderService.save(orderStore().$state);
                if (result.data.errors) {
                    this.setErrors(result.data.errors);
                } else {
                    window.goalManager.proceedYandexGoal('zakaz-success');
                    window.goalManager.proceedYandexGoal('success_all_lid');
                    window.goalManager.proceedVkGoal('zakaz-success');
                    window.goalManager.proceedVkGoal('success_all_lid');
                    window.goalManager.proceedGoogleGoal('zakaz-success');

                    if (result.data.pay_option === 10) {
                        this.redirectToSuccessPage(result.data.order_id);
                    } else {
                        switch (result.data.payment) {
                            case 20:
                                this.redirectToPaymentPage(result.data.order_id);
                                break;
                            case 30:
                                this.redirectToCreditPage(result.data.order_id);
                                break;
                            case 40:
                                this.redirectToInstallmentPage(result.data.order_id);
                                break;
                            default:
                                this.redirectToSuccessPage(result.data.order_id);
                                break;
                        }
                    }

                }
            } catch (error) {
                this.showError('Произошла ошибка. Попробуйте оформить заказ заново');
                console.error(error);
            }
        },
        setErrors(value) {
            this.errors = value;
        },
        redirectToSuccessPage(orderId) {
            window.location.pathname = `${this.redirectBaseUrl}/${orderId}`;
        },
        redirectToPaymentPage(orderId) {
            window.location.pathname = `${this.redirectPaymentUrl}/${orderId}`;
        },
        redirectToCreditPage(orderId) {
            window.location.pathname = `${this.redirectCreditUrl}/${orderId}`;
        },
        redirectToInstallmentPage(orderId) {
            window.location.pathname = `${this.redirectInstallmentUrl}/${orderId}`;
        },
        showError(message) {
            const notifications = notificationStore();
            notifications.showErrorNotification(message);
        },
    },
})