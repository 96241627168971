<template>
    <div :class="blockClasses">
        <label v-if="field.label" :class="elementClasses('label')">{{ field.label }}</label>
        <div :class="elementClasses('wrapper')" v-if="valueItem.value">
            <div :class="elementClasses('values')">
                <input type="text" v-model="valueItem.value.from">
                <input type="text" v-model="valueItem.value.to">
            </div>

            <!--
                :dot-size="8"
            -->
            <slider
                :model-value="valueModel"
                :min="field.data.min"
                :max="field.data.max"
                :step="field.data.step"
                @input="onSliderInput"
            />
        </div>
    </div>
</template>

<script>
import { fieldMixin } from "./fieldMixin";
import Slider from '@vueform/slider'

export default {
  name: "RangeField",
  mixins: [
    fieldMixin
  ],
  computed: {
    valueModel() {
      if (!this.valueItem.value) {
        return [0, 0];
      }

      return [
        this.valueItem.value.from,
        this.valueItem.value.to
      ]
    }
  },
  components: {
    Slider
  },
  methods: {
    checkValue() {
      if (!this.valueItem.value) {
        this.valueItem.value = {
          from: this.field.data.min,
          to: this.field.data.max
        };
      }
    },
    onSliderInput(value) {
      if (value[0] && value[1]) {
        this.valueItem.value.from = value[0];
        this.valueItem.value.to = value[1];
      }
    }
  },
  mounted() {
    this.checkValue();
  },
  beforeUpdate() {
    this.checkValue();
  }
}
</script>

<style lang="scss">

</style>