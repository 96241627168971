import {onDomReady} from "../../components/dynamic/observer";

onDomReady(() => {
    const articles = document.querySelectorAll('[data-add-goal-to-email]');

    articles.forEach((article) => {
        const articleElements = article.getElementsByTagName('a');

        if (articleElements.length > 0) {
            for (let link of articleElements) {
                link.addEventListener('click', () => {
                    window.goalManager.proceedVkGoal('click_email');
                    window.goalManager.proceedYandexGoal('click_email');
                    window.goalManager.proceedGoogleGoal('email#click');
                })
            }
        }
    });
})