import {onDomReady} from "../../components/dynamic/observer";
import {FreeMode, Swiper} from 'swiper';
import {CursorProgressBar} from "./cursorProgressBar/CursorProgressBar";

onDomReady(() => {
    const sliderEl = document.querySelector('[data-product-page-viewed-slider]');

    if (!sliderEl) {
        return;
    }

    const slider = new Swiper(sliderEl, {
        modules: [FreeMode],
        freeMode: true,
        breakpoints: {
            0: {
                slidesPerView: 2,
                spaceBetween: 8,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 18,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 28,
            }
        },
        on: {
            init: function (swiper) {
                if (swiper.$el[0].hasAttribute('data-cursor-progress-bar')) {
                    new CursorProgressBar(swiper);
                }
            },
            touchMove: function (swiper, event) {
                const customEvent = new CustomEvent('cursorMove', {
                    detail: {
                        offsetX: event.x,
                        offsetY: event.y,
                    },
                });
                window.dispatchEvent(customEvent);
            }
        },
    });
});