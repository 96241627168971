<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" viewBox="0 0 30 30"><path stroke="#000" stroke-width="2" d="M25 13.5v-9l-7 4-7.5-5-7.5 5v16l7.5-3L15 24M10.5 3v18M18 8.5v5"/><path stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21.85 26.512a.603.603 0 0 1-.7 0c-3.018-2.15-6.221-6.576-2.983-9.773a4.745 4.745 0 0 1 3.333-1.364c1.25 0 2.45.49 3.333 1.363 3.238 3.198.035 7.622-2.983 9.774Z"/><path stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M21.5 21a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Z"/></svg>
</template>

<script>
export default {
  name: "PickupIcon"
}
</script>

<style lang="scss">

</style>