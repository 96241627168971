<template>
  <div class="cart-pay">
    <CartBlockHeading :blockClass="'cart-page__block-heading'" :index="3" :title="'Способ оплаты'"/>

    <div class="cart-pay__content form-field">
      <ul class="cart-pay__radio-list">
        <li class="cart-pay__radio-item" data-cursor-expand v-for="paymentType in payments">
          <div class="cart-pay__radio">
            <input type="radio"
                   name="payment"
                   :id="`order_${paymentType.type}`"
                   :value="paymentType.type"
                   v-model="payment"
            >
            <label :for="`order_${paymentType.type}`" class="cart-pay__input-label">
              <span class="cart-pay__label-title text__cart-point">
                {{ paymentType.title }}
              </span>

              <span class="cart-pay__label-description text__main-small">
                {{ paymentType.description }}
              </span>
            </label>
          </div>
        </li>
      </ul>

      <div class="cart-pay__card-wrap">
        <div class="cart-pay__switcher switcher">
          <a href="javascript:void(0);"
             title="switcher-button"
             class="switcher__button"
             :class="{'_active': hasGifCard}"
             data-cursor-expand
             @click="isHasGiftCard"
          >
            <div class="switcher__inner"></div>
          </a>

          <div class="switcher__text text__cart-point">
            Есть подарочная карта
          </div>
        </div>

        <div class="cart-pay__form line-form line-form_light" :class="{'_hidden': !hasGifCard}">
          <div class="cart-pay__field cart-contacts__field line-form__field _half" data-hide-custom-cursor>
            <div class="smart-label" data-smart-label>
              <input type="text"
                     id="order_card"
                     class="cart-pay__input cart-contacts__input line-form__input"
                     v-model="giftCard"
              >

              <label for="order_card" class="cart-pay__label cart-contacts__label smart-label__primary">
                Введите номер
              </label>

              <label for="order_card" class="cart-pay__label cart-contacts__label smart-label__secondary">
                Введите номер
              </label>
            </div>
            <!--          <div class="line-form__field-errors"></div>-->
            <!--            <ItemErrors :errors="errors.card"/>-->
          </div>
        </div>
      </div>

      <TheCartPayOptions/>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapWritableState} from "pinia";
import {orderStore} from "../../store/order.store";
import ItemErrors from "./ItemErrors.vue";
import CartBlockHeading from "../parts/CartBlockHeading";
import {paymentStore} from "../../store/payment.store";
import TheCartPayOptions from "./TheCartPayOptions.vue";

export default {
  name: "TheCartOrderPay",
  data() {
    return {
      hasGifCard: false,
    }
  },
  components: {
    ItemErrors,
    CartBlockHeading,
    TheCartPayOptions,
  },
  computed: {
    ...mapWritableState(orderStore,[
        'payment',
        'giftCard'
    ]),
    ...mapState(paymentStore, [
        'payments'
    ])
  },
  methods: {
    ...mapActions(orderStore, [
        'setOrderGiftCard',
    ]),
    ...mapActions(paymentStore, [
        'fetchPayment',
    ]),
    isHasGiftCard() {
      this.hasGifCard = !this.hasGifCard;
    },
  },
  mounted() {
    this.fetchPayment();
  }
}
</script>

<style lang="scss">

</style>