import {createPinia, defineStore} from "pinia";
import { initFilters } from "./filter/init";
import {createApp} from "vue";
import mitt from 'mitt';
import TheProductOrderConfig from "./components/ProductOrderConfig/TheProductOrderConfig.vue";
import TheProductOrderSticky from "./components/ProductOrderConfig/TheProductOrderSticky.vue";

import CartPreviewModal from "./components/cart/CartPreviewModal.vue";
import TheCartPage from "./components/cart/TheCartPage";
import TheNotification from "./components/notification/TheNotification";
import TheCartWidget from "./components/cart/TheCartWidget";
import {onDomChanges} from "../components/dynamic/observer";

const emitter = mitt();

const pinia = createPinia();
initFilters(pinia);

// Product order config
const productOrderConfigEl = document.querySelector('#product-order-config');
if (productOrderConfigEl) {
    const app = createApp(
        TheProductOrderConfig,
        {
            id: parseInt(productOrderConfigEl.dataset.productId, 10),
        }
    ).use(pinia);
    app.mount('#product-order-config');
}

const productOrderStickyEl = document.querySelector('#product-order-sticky');
if (productOrderStickyEl) {
    document.addEventListener('getProductOptions', () => {
        const app = createApp(
          TheProductOrderSticky,
          {
              id: parseInt(productOrderConfigEl.dataset.productId, 10),
          }
        ).use(pinia);
        app.mount('#product-order-sticky');
    });
}

if (document.querySelector('#cart-page')) {
    const app = createApp(
        TheCartPage,
    ).use(pinia);
    app.mount('#cart-page');
}

if (document.querySelector('#cart-widget')) {
    const app = createApp(
        TheCartWidget
    ).use(pinia);
    app.mount('#cart-widget');
}

if (document.querySelector('#cart-widget-menu')) {
    const app = createApp(
        TheCartWidget
    ).use(pinia);
    app.mount('#cart-widget-menu');
}

if (document.querySelector('#notifications')) {
    const app = createApp(
        TheNotification,
    ).use(pinia);
    app.mount('#notifications');
}

onDomChanges(() => {
    // cart preview modal
    const cartPreviewModalEl = document.querySelector('#cart-preview-modal');
    if (cartPreviewModalEl) {
        const app = createApp(
            CartPreviewModal,
        ).use(pinia);
        app.mount('#cart-preview-modal');
    }
});