import axios from 'axios';

const OrderService = {
    async getOptions(data) {
        return axios.post('/catalog/api/get_product_options', data);
    },
    async getState(data) {
        return axios.post('/catalog/api/get_product_state', data);
    },
    async setState(data) {
        return axios.post('/catalog/api/set_product_state', data);
    },
};

export default OrderService;
