import {onDomReady} from "../../components/dynamic/observer";

window.flagsMap = {};

onDomReady(() => {
  console.log(`Init varioqub experiment pending, wait for metrika...`);
  let countPings = 0;
  const availPings = 15;
  // Metrika load pending
  const pingInterval = setInterval(() => {
    if (Ya && Ya.Metrika2) {
      handleFlags();
      clearInterval(pingInterval);
    } else {
      const left = availPings - countPings;
      console.log(`Pending init varioqub experiment, metrika not available ${left} attempts left`);
      countPings++;
      if (countPings >= availPings) {
        console.log('Unable init varioqub experiment, metrika not available');
        clearInterval(pingInterval);
      }
    }
  }, 500);
});

function handleFlags() {
  const id = Ya.Metrika2.counters()[0].id
  ymab(`metrika.${id}`, 'getFlags', function(flags) {
    const flagsMap = {};
    for (const [key, value] of Object.entries(flags)) {
      flagsMap[key] = value[0];
    }
    console.log('Varioqub flagsMap: ', flagsMap);
    onInitExperiments(flagsMap);
  });
}

function onInitExperiments(flagsMap)
{
  window.flagsMap = flagsMap;
  const event = new CustomEvent('onInitExperiments', {
    detail: {
      flagsMap
    }
  });
  document.dispatchEvent(event);
}