import {onDomReady} from "../../components/dynamic/observer";
import {Pagination, Swiper} from 'swiper';

onDomReady(() => {
    const container = document.querySelector('[data-reviews-common-slider-container]');
    if (!container) {
        return;
    }

    const sliderEl = container.querySelector('[data-reviews-common-slider]');
    if (!sliderEl) {
        return;
    }

    const slider = new Swiper(sliderEl, {
        modules: [Pagination],
        slidesPerView: 3,
        pagination: {
            el: container.querySelector('[data-reviews-common-slider-pagination]'),
            type: 'bullets',
        },
        breakpoints: {
            0: {
                slidesPerView: 'auto',
            },
            768: {
                slidesPerView: 2,
            },
            1600: {
                slidesPerView: 3,
            }
        },
    });
});