<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="28" fill="none"><path stroke="#A39F9D" d="M17.396 3.382a3.49 3.49 0 0 1 5.351 4.483L8.867 24.431a.5.5 0 0 1-.34.177l-4.714.404-.409-4.703a.5.5 0 0 1 .115-.364L17.396 3.382ZM16.808 4.856l4.596 3.857M4.212 19.09l5.363 4.499"/></svg>
</template>

<script>
export default {
  name: "PencilIcon"
}
</script>

<style lang="scss">

</style>