import {onDomReady} from "../../components/dynamic/observer";
import {Autoplay, FreeMode, Swiper} from 'swiper';

onDomReady(() => {
    const sliderEl = document.querySelector('[data-example-slider]');

    if (!sliderEl) {
        return;
    }

    const slider = new Swiper(sliderEl, {
        modules: [FreeMode, Autoplay],
        freeMode: true,
        speed: 15000,
        autoplay: {
            enabled: true,
            delay: 1,
        },
        slidesPerView: 'auto',
        loop: true,
        breakpoints: {
            0: {
                spaceBetween: 8,
            },
            768: {
                spaceBetween: 18,
            },
            1100: {
                spaceBetween: 28,
            }
        },
        on: {
            touchMove: function (swiper, event) {
                const customEvent = new CustomEvent('cursorMove', {
                    detail: {
                        offsetX: event.x,
                        offsetY: event.y,
                    },
                });
                window.dispatchEvent(customEvent);
            }
        },
    });
});