import {onDomReady} from "../../components/dynamic/observer";
import {Autoplay, FreeMode, Navigation, Pagination, Swiper} from 'swiper';

onDomReady(() => {
    const sliderEl = document.querySelector('[data-picture-material-slider]');

    if (!sliderEl) {
        return;
    }

    const slider = new Swiper(sliderEl, {
        modules: [Navigation, Pagination],
        slidesPerView: 'auto',
        navigation: {
            prevEl: document.querySelector('[data-picture-material-slider-prev]'),
            nextEl: document.querySelector('[data-picture-material-slider-next]')
        },
        pagination: {
            el: sliderEl.querySelector('[data-picture-material-slider-pagination]'),
            type: 'bullets',
        },
        breakpoints: {
            0: {
                spaceBetween: 8,
            },
            768: {
                spaceBetween: 18,
            },
            1024: {
                spaceBetween: 0,
            }
        }
    });
});