<template>
  <TransitionGroup name="notifications-list" class="notifications">
    <div class="notification" v-for="(notification, key) in notifications" :key="notification.key" :class="['notification_' + notification.type]">
      <div class="notification__text">
        {{ notification.text }}
      </div>
      <a class="notification__close" href="javascript:void(0);" @click.prevent="remove(key)">
        &times;
      </a>
    </div>
  </TransitionGroup>
</template>

<script>
import {mapActions, mapState} from "pinia";
import {notificationStore} from "../../store/notification.store";

export default {
  name: "TheNotifications",
  computed: {
    ...mapState(notificationStore, [
      'notifications'
    ])
  },
  methods: {
    ...mapActions(notificationStore, [
      'removeNotification'
    ]),
    remove(key) {
      this.removeNotification(key)
    }
  }
}
</script>

<style lang="scss">

</style>