<template>
  <a href="/cart"
     class="header__cart-wrap link _line"
     :class="{'_price': count}"
     data-cursor-expand
     title="Корзина"
     data-goal-yandex-click="go_cart"
     data-goal-vk-click="go_cart"
     data-goal-google-click="go_cart#click"
  >
    <div class="header__cart">
      <span class="header__cart-icon">
        <CartIcon/>
        <span class="header__cart-count" v-show="count">
           {{ count }}
        </span>
      </span>
    </div>
    <span class="header__link" v-if="!count">
      Корзина
    </span>
    <span class="header__link" v-else>
      {{price(amount)}} ₽
    </span>
  </a>
</template>

<script>
import {mapActions, mapState} from "pinia";
import {cartStore} from "../../store/cart.store";
import CartIcon from "../icons/CartIcon";

export default {
  name: "TheCartWidget",
  components: {
    CartIcon
  },
  computed: {
    ...mapState(cartStore, [
      'checkout',
      'count',
    ]),
    items() {
      return this.checkout ? this.checkout.items : [];
    },
    amount() {
      return this.checkout ? this.checkout.total : 0;
    },
  },
  methods: {
    ...mapActions(cartStore, [
      'fetchCheckout',
    ]),
    price(price) {
      return new Intl.NumberFormat('ru-RU', {style: 'decimal'}).format(price);
    }
  },
  mounted() {
    this.fetchCheckout();
  },
}
</script>