<template>
  <div class="cart-positions">
    <ul class="cart-positions__list">
      <li class="cart-positions__item" v-for="item in items" v-show="!loading">
        <div class="cart-positions__item-info-wrap">
          <div class="cart-positions__image-wrap">
            <img :src="item.imagePath"
                 :alt="item.buyable.product.name"
                 class="cart-positions__image"
                 v-show="item.imagePath"
            >
          </div>

          <div class="cart-positions__title h5">
            {{ item.buyable.product.title }}
          </div>

          <a href="javascript:void(0);"
             title="Удалить"
             class="cart-positions__remove button button__close _icon"
             data-cursor-expand
             @click="remove(item.key)"
             data-goal-yandex-click="click_delete_tovar"
             :data-goal-vk-click="`click_delete_tovar|${item.buyable.product.id}`"
             data-goal-google-click="delete_tovar#click"
          >
            <span class="button__close-text-wrap">
              Удалить
            </span>

            <span class="cart-positions__remove-icon button__close-icon-wrap"></span>
          </a>

          <a :href="`/catalog/${item.buyable.categorySlug}/${item.buyable.productSlug}`"
             class="cart-positions__change link link__text-with-arrow _left"
             title="Выбрать другую"
             data-cursor-expand
          >
            <span class="link__text-with-arrow-text-wrap">
              Выбрать другую
            </span>

            <span class="link__text-with-arrow-icon">
              <Arrow/>
            </span>
          </a>

          <div class="cart-positions__config">
            {{ item.buyable.name }} {{ item.buyable.description }}
          </div>

          <div class="cart-positions__container" data-accordion-container>
            <div class="cart-positions__element" data-accordion-element>
              <div class="cart-positions__block" data-accordion-block>
                <div class="cart-positions__inner-block">
                  <ul class="cart-positions__chars-list">
                    <li class="cart-positions__item-char" v-for="(value, key) in item.data.humanReadable.chars">
                      <div class="cart-positions__char cart-positions__char_name">
                        {{ key }}
                      </div>

                      <div class="cart-positions__char cart-positions__char_value">
                        {{ value }}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <a href="javascript:void(0);"
                 class="cart-positions__link"
                 data-accordion-link
                 data-cursor-expand
                 data-toggle-text
                 data-toggle="_opened"
                 data-old-text="Детали отделки"
                 data-new-text="Свернуть детали отделки"
                 :data-selector="`span.cart-positions__link-text._${item.buyable.product.id}`"
                 :data-selector-second="`span.cart-positions__link-icon._${item.buyable.product.id}`"
              >
                <span class="cart-positions__link-text" :class="`_${item.buyable.product.id}`">
                  Детали отделки
                </span>
                <span class="cart-positions__link-icon" :class="`_${item.buyable.product.id}`">
                  <AccordionArrow/>
                </span>
              </a>
            </div>
          </div>

          <div class="cart-positions__total-field-wrap">
            <div class="cart-positions__total-field total-field">
              <a href="javascript:void(0);"
                 class="total-field__dec"
                 @click="decreaseItem(item)"
                 data-goal-yandex-click="tovar_minus"
                 :data-goal-vk-click="`tovar_minus|${item.buyable.product.id}`"
                 data-goal-google-click="tovar_minus#click"
              >
              </a>
              <input type="number" class="total-field__input" :value="item.quantity" min="1" disabled>
              <a href="javascript:void(0);"
                 class="total-field__inc"
                 @click="increaseItem(item)"
                 data-goal-yandex-click="tovar_plus"
                 :data-goal-vk-click="`tovat_plus|${item.buyable.product.id}`"
                 data-goal-google-click="tovar_plus#click"
              >
              </a>
            </div>

            <div class="cart-positions__prices-wrap">
              <div class="cart-positions__item-price cart-positions__item-price_old" v-show="item.buyable.old_price">
                {{ price(item.buyable.old_price) }} ₽
              </div>

              <div class="cart-positions__item-price h4">
                {{ price(item.price) }} ₽
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapActions, mapState, mapWritableState} from "pinia";
import {cartStore} from "../../store/cart.store";
import Arrow from "../icons/Arrow";
import AccordionArrow from "../icons/AccordionArrow";

export default {
  name: "TheCartPositions",
  components: {
    Arrow,
    AccordionArrow
  },
  computed: {
    ...mapState(cartStore, [
      'checkout',
      'loading'
    ]),
    items() {
      return this.checkout ? this.checkout.items : [];
    }
  },
  methods: {
    ...mapActions(cartStore, [
      'fetchCheckout',
      'increase',
      'decrease',
      'remove'
    ]),
    decreaseItem(item) {
      if (item.quantity - 1 <= 0) {
        this.remove(item.key);
      }
      this.decrease(item.key, 1);
    },
    increaseItem(item) {
      this.increase(item.key, 1);
    },
    price(price) {
      return new Intl.NumberFormat('ru-RU', {style: 'decimal'}).format(price);
    }
  },
  mounted() {
    this.fetchCheckout();
  },
}
</script>
