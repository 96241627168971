const header = document.querySelector('[data-header]');
const hamburger = document.querySelector('[data-header-hamburger]');
const dropMenuHamburger = document.querySelector('[data-drop-menu-hamburger]');
const dropMenu = document.querySelector('[data-drop-menu]');
// let links = document.querySelectorAll('[data-drop-link]');

// const dropMenuCatalogOpen = document.querySelector('[data-header-drop-menu-catalog-open]');
// const dropMenuCatalogClose = document.querySelector('[data-header-drop-menu-catalog-close]');
// const dropMenuCatalog = document.querySelector('[data-header-drop-menu-catalog]');
// const dropMenuCatalogCategoriesButton = document.querySelectorAll('[data-header-drop-menu-catalog-categories-button]');

if (header && hamburger && dropMenu && dropMenuHamburger) {
    hamburger.addEventListener('click', () => {
        hamburger.classList.add('_opened');
        dropMenuHamburger.classList.add('_opened');
        dropMenu.classList.add('_opened');
    });

    dropMenuHamburger.addEventListener('click', () => {

        hamburger.classList.remove('_opened');
        dropMenuHamburger.classList.remove('_opened');
        dropMenu.classList.remove('_opened');
    });

    // links.forEach((link) => {
    //     link.addEventListener('click', ()=>{
    //         hamburger.classList.remove('_open');
    //         header.classList.remove('_black');
    //         menu.classList.remove('_open');
    //     })
    // });

    // if (dropMenuCatalogOpen && dropMenuCatalogClose) {
    //     dropMenuCatalogOpen.addEventListener('click', () => {
    //         dropMenuCatalog.classList.add('_open');
    //     });
    //
    //     dropMenuCatalogClose.addEventListener('click', () => {
    //         dropMenuCatalog.classList.remove('_open');
    //     });
    // }

    // if (window.innerWidth < 768 && dropMenuCatalogCategoriesButton) {
    //     dropMenuCatalogCategoriesButton.forEach((item) => {
    //         item.addEventListener('click', () => {
    //             item.classList.toggle('_open');
    //         });
    //     });
    // }
}