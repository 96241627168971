import {onDomReady} from "../../components/dynamic/observer";

onDomReady(() => {
    let form = null;
    const typeDesigner = 'designer';
    const typePartner = 'partner';

    document.addEventListener('modal-opened', () => {
        form = document.querySelector('[data-goal-by-parameter]');
    });

    document.addEventListener('modal-form:success', (e) => {

        if (!form) {
            return;
        }

        const radioButtonValue = form.querySelector('input[type = "radio"]:checked').value;

        if (!radioButtonValue) {
            return;
        }

        if (radioButtonValue === typeDesigner) {
            window.goalManager.proceedVkGoal('svyazatsya_s_nami_dizayner');
            window.goalManager.proceedYandexGoal('svyazatsya_s_nami_dizayner');
            window.goalManager.proceedGoogleGoal('svyazatsya_s_nami#success');
        } else if (radioButtonValue === typePartner) {
            window.goalManager.proceedVkGoal('svyazatsya_s_nami_partner');
            window.goalManager.proceedYandexGoal('svyazatsya_s_nami_partner');
            window.goalManager.proceedGoogleGoal('svyazatsya_s_nami#success');
        }
    });
});