import axios from 'axios';

const CartService = {
    async fetchCheckout() {
        return axios.get('/cart/api/index');
    },
    async add(data) {
        return axios.post('/cart/api/add', data);
    },
    async remove(key) {
        return axios.post('/cart/api/remove', {
            key
        });
    },
    async increase(key, amount) {
        return axios.post('/cart/api/increase', {
            key,
            amount
        });
    },
    async decrease(key, amount) {
        return axios.post('/cart/api/decrease', {
            key,
            amount
        });
    },
    async applyPromocode(promocode) {
        return axios.post('/cart/api/apply-promocode', {
            promocode,
        })
    }
};

export default CartService;