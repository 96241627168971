<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" fill="none" viewBox="0 0 20 24"><path stroke="#000" stroke-linecap="round" stroke-linejoin="round" d="M13.5 6.5V4.08c0-.95-.369-1.86-1.025-2.531A3.46 3.46 0 0 0 10 .5 3.46 3.46 0 0 0 7.525 1.55 3.622 3.622 0 0 0 6.5 4.08V6.5"/><path stroke="#000" d="M.943 5.956A1.5 1.5 0 0 1 2.442 4.5h15.116a1.5 1.5 0 0 1 1.5 1.456l.382 13a1.5 1.5 0 0 1-1.5 1.544H2.06a1.5 1.5 0 0 1-1.5-1.544l.383-13Z"/></svg>
</template>

<script>
export default {
  name: "CartIcon"
}
</script>

<style lang="scss">

</style>