<template>
  <div class="cart-comment">
    <CartBlockHeading :blockClass="'cart-page__block-heading'" :index="4" :title="'Комментарий к заказу'"/>

    <div class="cart-comment__form line-form line-form_light">
      <div class="cart-comment__field line-form__field" data-hide-custom-cursor>
        <div class="smart-label" data-smart-label>
          <textarea
              name="comment"
              id="order_comment"
              cols="30"
              rows="10"
              class="cart-comment__comment"
              v-model="comment"
          >
          </textarea>

          <label for="order_comment" class="cart-comment__label cart-contacts__label smart-label__primary">
            Комментарий
          </label>

          <label for="order_comment" class="cart-comment__label cart-contacts__label smart-label__secondary">
            Комментарий
          </label>
        </div>
        <!--          <div class="line-form__field-errors"></div>-->
        <!--            <ItemErrors :errors="errors.card"/>-->
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapWritableState} from "pinia";
import CartBlockHeading from "../parts/CartBlockHeading";
import {orderStore} from "../../store/order.store";

export default {
  name: "TheCartOrderComment",
  components: {
    CartBlockHeading
  },
  computed: {
    ...mapWritableState(orderStore, [
        'comment'
    ])
  }
}
</script>

<style lang="scss">

</style>