import {onDomChanges, onDomReady} from "../../components/dynamic/observer";

class TotalField {
    constructor(el) {
        this.field = el;
        this.input = this.field.querySelector('[data-total-field-input]');
        this.dec = this.field.querySelector('[data-total-field-dec]');
        this.inc = this.field.querySelector('[data-total-field-inc]');
        if (!this.input && !this.dec && !this.inc) {
            console.log('TotalField: could not found control elems');
            return false;
        }

        this.priceLabel = this.field.closest('form').querySelector('[data-price-label]');
        this.priceInput = this.field.closest('form').querySelector('[data-price-input]');
        if (!this.priceLabel && !this.priceInput) {
            console.log('TotalField: could not found price field');
            return false;
        }

        this.numberFormat = new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', minimumFractionDigits: 0 });
        this.productPrice = this.priceLabel.dataset.priceLabel;
        if (!this.productPrice) {
            console.log('TotalField: could not get product price');
            return false;
        }
        this.priceInput.value = this.input.value * this.productPrice;

        this.bind();
    }

    bind() {
        this.dec.addEventListener('click', () => {
            if ((this.input.value - 1) < 1) {
                return false;
            }
            this.input.value--;
            this.input.dispatchEvent(new Event('change'));
        });

        this.inc.addEventListener('click', () => {
            this.input.value++;
            this.input.dispatchEvent(new Event('change'));
        });

        this.input.addEventListener('change', (e) => {
            this.priceLabel.innerHTML = `${this.numberFormat.format(this.productPrice * e.target.value)}`;
            this.priceInput.value = this.productPrice * e.target.value;

            if (e.target.value <= 0) {
                this.input.value = 1;
                this.input.dispatchEvent(new Event('change'));
            }
        });
    }
}

const init = () => {
    const el = document.querySelector('[data-total-field]');
    if (!el) {
        return;
    }
    new TotalField(el);
};

onDomReady(() => init());
onDomChanges(() => init());