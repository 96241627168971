<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" fill="none" viewBox="0 0 10 7"><path stroke="#000" d="m1 2 4 4 4-4"/></svg>
</template>

<script>
export default {
  name: "AccordionArrow"
}
</script>

<style lang="scss">

</style>