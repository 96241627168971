<template>
  <div class="line-form__field-errors">
    <ul class="errors" data-errors v-if="errors && errors.length > 0">
      <li v-for="error in errors" class="cart-errors__error">
        {{ error }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ItemErrors",
  props: ['errors']
}
</script>

<style lang="scss">

</style>