import { onDomReady } from '../../components/dynamic/observer';
import { scrollToTarget } from "../../components/scroll/scroll";

class Header {
  constructor(selector) {
    this.header = selector;
    this.headerHeight = this.header.offsetHeight;
    this.headerContainer = this.header.querySelector('[data-header-container]');
    this.headerInner = this.header.querySelector('[data-header-inner]');
    this.dropMenu = selector.querySelector('[data-drop-menu]');
    this.dropMenuLinks = this.header.querySelectorAll('[data-drop-menu-link]');

    this.catalogFilterSticky = document.querySelector('[data-catalog-filter-sticky]');
    this.catalogFilterBlock = document.querySelector('[data-catalog-filter-sticky-block]');

    this.hamburger = selector.querySelector('[data-hamburger]');
    this.footer = document.querySelector('[data-footer]');

    this.aboutMenu = document.querySelector('[data-about-menu]');

    this.isOpened = false;
    this.scrollTop = 0;

    this.productOrderSticky = document.querySelector('#product-order-sticky div');
    this.productConfigBlock = document.querySelector('[data-product-config-block]');

    this.eventListeners();
  }

  open() {
    this.isOpened = true;
    this.header.classList.add('_opened');
    this.dropMenu.classList.add('_opened');
    this.hamburger.classList.add('_opened');
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100%';
  }

  close() {
    this.isOpened = false;
    this.header.classList.remove('_opened');
    this.dropMenu.classList.remove('_opened');
    this.hamburger.classList.remove('_opened');
    document.body.style.overflow = '';
    document.body.style.height = '';
  }

  addSticky() {
    const instance = this;
    if (instance.scrollTop > instance.headerHeight) {
      instance.header.classList.add('_sticky');
    } else {
      instance.header.classList.remove('_sticky');
    }

    if (instance.scrollTop > 0) {
      instance.headerContainer.classList.add('_sticky');
    } else {
      instance.headerContainer.classList.remove('_sticky');
    }

    this.addProductOrderSticky();
    this.addCatalogFilterSticky();
  }

  handleHeader() {
    const instance = this;

    if (instance.scrollTop < instance.lastScrollTop) { // up
      if (instance.scrollTop > instance.headerHeight) {
        // скролтоп ниже высоты шапки
        instance.header.classList.add('_up');
        instance.headerContainer.style.transform = 'translate3d(0, 0, 0)';

        if (this.productOrderSticky) {
          this.productOrderSticky.classList.add('_up');
        }

        if (this.catalogFilterSticky) {
          this.catalogFilterSticky.classList.add('_up');
        }
      }

      if (instance.scrollTop <= instance.headerHeight) {
        // скролтоп в зоне высоты шапки
        if (!instance.header.classList.contains('_up')) {
          instance.headerContainer.style.transform = `translate3d(0, -${instance.scrollTop}px, 0)`;
        }
      }

      if (instance.scrollTop <= 0) {
        instance.header.classList.remove('_up');
        instance.headerContainer.style.transform = '';
        instance.headerContainer.style.transition = '';
      }

    } else { // down
      instance.header.classList.remove('_up');
      if (this.productOrderSticky) {
        this.productOrderSticky.classList.remove('_up');
      }

      if (this.catalogFilterSticky) {
        this.catalogFilterSticky.classList.remove('_up');
      }

      if (instance.scrollTop <= instance.headerHeight) {
        // скролтоп в зоне высоты шапки
        instance.headerContainer.style.transform = `translate3d(0, -${instance.scrollTop}px, 0)`;
        instance.headerContainer.style.transition = '';
      } else {
        // скролтоп ниже высоты шапки
        instance.headerContainer.style.transform = 'translate3d(0, -100%, 0)';
        instance.headerContainer.style.transition = 'transform .5s cubic-bezier(.65,0,.25,1)';
      }
    }
  }

  updateFooterPosition() {
    const instance = this;
    if (instance.footer) {
      instance.footerTop = instance.footer.getBoundingClientRect().top;
    }
  }

  translateHeaderByFooter() {
    const instance = this;
    if (instance.footer) {
      const offset = instance.headerInner.offsetHeight - instance.footerTop;
      if (offset > 0) {
        instance.headerInner.style.transform = `translate3d(0, -${offset}px, 0)`;
      } else {
        instance.headerInner.style.transform = '';
      }
    }
  }

  eventListeners() {
    document.addEventListener('ProductOrderConfigInit', () => {
        this.productOrderSticky = document.querySelector('#product-order-sticky div');
        this.productConfigBlock = document.querySelector('[data-product-config-block]');
    });

    document.addEventListener('getProductOptions', () => {
        this.productOrderSticky = document.querySelector('#product-order-sticky div');
        this.productConfigBlock = document.querySelector('[data-product-config-block]');
    });

    if (this.hamburger) {
      this.hamburger.addEventListener('click', () => {
        this.isOpened ? this.close() : this.open();
      });
    }

    document.addEventListener('click', (e) => {
      if (this.isOpened && this.dropMenu === e.target) {
        this.close();
      }
    });

    window.addEventListener('scroll', () => {
      const instance = this;
      instance.scrollTop = window.scrollY;
      instance.updateFooterPosition();

      instance.addSticky();

      instance.handleHeader();
      instance.translateHeaderByFooter();

      instance.lastScrollTop = instance.scrollTop;
    });

    this.dropMenuLinks.forEach((item) => {
      item.addEventListener('click', () => {
        if (!this.isOpened) {
          const target = document.querySelector(item.dataset.dropMenuLink);
          const offset = 0;

          scrollToTarget(target, offset);
        } else {
          this.close();
          setTimeout(() => {
            const target = document.querySelector(item.dataset.dropMenuLink);
            const offset = 0;

            scrollToTarget(target, offset);
          }, 500);
        }
      });
    });
  }

  addProductOrderSticky() {
    const instance = this;
    if (this.productConfigBlock && this.productOrderSticky) {
      const productConfigBottom = this.productConfigBlock.offsetTop + this.productConfigBlock.offsetHeight;

      if (window.innerWidth >= 768) {
        if (instance.scrollTop > productConfigBottom) {
          this.productOrderSticky.classList.add('_sticky');
        } else {
          this.productOrderSticky.classList.remove('_sticky');
        }
      } else {
        if (instance.scrollTop > productConfigBottom && instance.footerTop > window.innerHeight) {
          this.productOrderSticky.classList.add('_sticky');
        } else {
          this.productOrderSticky.classList.remove('_sticky');
        }
      }
    }
  }

  addCatalogFilterSticky() {
    const instance = this;
    if (this.catalogFilterBlock && this.catalogFilterSticky) {
      const catalogFilterBottom = this.catalogFilterBlock.offsetTop + this.catalogFilterBlock.offsetHeight;

      if (window.innerWidth >= 768) {
        if (instance.scrollTop > catalogFilterBottom) {
          this.catalogFilterSticky.classList.add('_sticky');
        } else {
          this.catalogFilterSticky.classList.remove('_sticky');
        }
      } else {
        if (instance.scrollTop > catalogFilterBottom && instance.footerTop > window.innerHeight) {
          this.catalogFilterSticky.classList.add('_sticky');
        } else {
          this.catalogFilterSticky.classList.remove('_sticky');
        }
      }
    }
  }
}

onDomReady(() => {
  const headerSelector = document.querySelector('[data-header]');
  if (headerSelector) {
    new Header(headerSelector);
  }
});