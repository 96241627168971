import {defineStore} from "pinia";
import paymentService from "../services/payment.service";

export const paymentStore = defineStore('cartPayment', {
    state: () => (
        {
            payments: [],
        }
    ),
    actions: {
        async fetchPayment() {
            try {
                const payment = await paymentService.fetchPayment();
                this.payments = payment.data;
            } catch (e) {
                console.log(e);
            }
            this.setLoaded(true);
        },
        setLoaded(value) {
            this.loaded = value;
        }
    }
})