import {onDomChanges} from "../../components/dynamic/observer";
import {singleInit} from "../../components/dynamic/bind";

class SmartLabel {
    constructor(element) {
        this.element = element;
        this.input = element.querySelector('input') ?? element.querySelector('textarea');
        this.bind();
        this.watch();
    }
    bind() {
        this.input.addEventListener('focus', () => {
            this.watch();
        });
        this.input.addEventListener('blur', () => {
            this.watch();
        });
        this.input.addEventListener('input', () => {
            this.watch();
        });
        this.input.addEventListener('change', () => {
            this.watch();
        });
        this.input.addEventListener('keyup', () => {
            this.watch();
        });
        const form = this.input.closest('form');
        if (form) {
            form.addEventListener('reset', () => {
                setTimeout(() => {
                    this.watch();
                }, 100)
            })
        }
    }
    watch() {
        const focus = this.input.matches(':focus');
        const value = this.input.value.trim();

        if (value) {
            this.element.classList.remove('_focus', '_default');
            this.element.classList.add('_typing');
            return;
        }
        if (focus) {
            this.element.classList.remove('_typing','_default');
            this.element.classList.add('_focus');
            return;
        }
        this.element.classList.remove('_focus', '_typing');
        this.element.classList.add('_default');
    }
}

onDomChanges(() => {
    document.querySelectorAll('[data-smart-label]').forEach((smartLabelEl) => {
        singleInit(smartLabelEl, 'initialized',  () => {
            new SmartLabel(smartLabelEl);
        })
    });
})

document.addEventListener('cartDeliveryLabel', () => {
    document.querySelectorAll('[data-smart-label]').forEach((smartLabelEl) => {
        singleInit(smartLabelEl, 'initialized',  () => {
            new SmartLabel(smartLabelEl);
        })
    });
});