<template>
  <div class="cart-delivery line-form line-form_light">
    <div class="cart-contacts__field line-form__field _half">
      <div class="cart-delivery__smart-label smart-label" data-smart-label>
        <input type="text"
               id="order_delivery"
               name="address"
               class="cart-contacts__input line-form__input"
               data-hide-custom-cursor
               v-model="address"
        >

        <label for="order_delivery" class="cart-contacts__label smart-label__primary">
          Адрес доставки
        </label>

        <label for="order_delivery" class="cart-contacts__label smart-label__secondary">
          Адрес доставки
        </label>
      </div>
      <ItemErrors :errors="errors.addressFilled"/>
    </div>
    <div class="cart-delivery__tc-info">
      Работаем с ТК, по Москве доставляем своим транспортом. Доставка оплачивается при получении.
    </div>
  </div>
</template>

<script>
import {mapWritableState, mapState} from "pinia";
import {orderStore} from "../../store/order.store";
import ItemErrors from "./ItemErrors";

export default {
  name: "TheCartReceivingDelivery",
  components: {
    ItemErrors,
  },
  computed: {
    ...mapWritableState(orderStore, [
       'address'
    ]),
    ...mapState(orderStore, [
       'errors'
    ])
  }
}
</script>

<style lang="scss">

</style>