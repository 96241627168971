import {onDomReady} from "../../components/dynamic/observer";
import CryptoJS from "crypto-js";

const TPF = document.getElementById("payform-tinkoff");

if (TPF) {
    TPF.addEventListener("submit", function (e) {
        e.preventDefault();
        const {description, amount, phone, receipt, token, terminalkey, order} = TPF;
        if (receipt) {
            if (!phone.value) {
                return alert("Поле 'Телефон' не должно быть пустым");
            }

            const tokenValue = amount.value + description.value + order.value + '#vzWf!JFTPOLjOsC' + terminalkey.value;
            const utf8 = CryptoJS.enc.Utf8.parse(tokenValue);
            token.value = CryptoJS.SHA256(utf8).toString(CryptoJS.enc.Base64);
        }

        pay(TPF);
    })

    onDomReady(() => {
        setTimeout(() => {
            const button = document.querySelector('.payform-tinkoff-row.payform-tinkoff-btn');
            if (button) {
                button.click();
            }
        }, 1500)
    });
}