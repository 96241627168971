class PickupPointsMap {
  constructor({ element, points }) {
    this.element = element;
    this.points = points;
    // this.active
    this.offices = [];
    this.map = null;
    this.center = [0, 0];
    this.zoom = 16;
    this.currentValue = null;

    this.initObject();
    this.createMap();
    this.eventListeners();
  }

  eventListeners() {
    this.element.addEventListener('pickupPoint:point:changed', (e) => {
      this.currentValue = e.detail.value;
      this.centerPickPoint();
    });
  }

  centerPickPoint() {
    const office = this.offices.find((office) => office.id === this.currentValue);
    this.map.setCenter([office.lat, office.lng], this.zoom, {duration: 500});
  }

  initObject() {
    this.points.forEach((point) => {
      const placemark = this.createPlacemark({
        lat: point.addressLat,
        lng: point.addressLng
      });

      this.offices.push({
        id: point.id,
        placemark,
        lat: point.addressLat,
        lng: point.addressLng,
        zoom: this.zoom
      });
    });
  }

  createMap() {
    this.element = document.querySelector('[data-cart-map]');

    this.map = new ymaps.Map(this.element, {
      center: this.center,
      zoom: this.zoom,
      controls: ['zoomControl'],
    }, {
      zoomControlSize: 'small',
    });
    this.map.behaviors.disable('scrollZoom');

    this.map.margin.setDefaultMargin([31, 31, 31, 31]);

    this.fitCenter();

    this.offices.map((office) => {
      this.map.geoObjects.add(office.placemark);
    });
  }

  getCenterCoordsByAllVisibleOffices() {
    // Берем все координаты от всех точек
    let minLat = this.offices[0].lat;
    let minLng = this.offices[0].lng;
    let maxLat = this.offices[0].lat;
    let maxLng = this.offices[0].lng;

    // this.offices.forEach((office) => {
    //   if (minLat === undefined || office.lat) {
    //     minLat = office.lat;
    //   }
    //   if (maxLat === undefined || maxLat < office.lat) {
    //     maxLat = office.lat;
    //   }
    //
    //   if (minLng === undefined || minLng > office.lng) {
    //     minLng = office.lng;
    //   }
    //   if (maxLng === undefined || maxLng < office.lng) {
    //     maxLng = office.lng;
    //   }
    // });

    return [[minLat, minLng], [maxLat, maxLng]];
  }

  fitCenter() {
    const coords = this.getCenterCoordsByAllVisibleOffices();
    // Определяем центр и оптимальный зум
    ymaps.util.requireCenterAndZoom(
      this.map.getType(),
      coords,
      this.map.container.getSize(),
      { margin: [31, 31, 31, 31] },
    ).then((result) => {
      this.map.setCenter(result.center, this.zoom, {duration: 500});
    });
  }

  createPlacemark({lat, lng}) {
    return new ymaps.Placemark([lat, lng], {}, {
      // Опции.
      // Необходимо указать данный тип макета.
      iconLayout: 'default#image',
      // Своё изображение иконки метки.
      iconImageHref: '/static/images/svg/baloon.svg',
      // Размеры метки.
      iconImageSize: [35, 42],
      // Смещение левого верхнего угла иконки относительно
      // её "ножки" (точки привязки).
      iconImageOffset: [-17, -40],
    });
  }
}

document.addEventListener('pickupPoint:map:init', (e) => {
  new PickupPointsMap(e.detail);
})